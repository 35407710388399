import httpClient from "../Constant/httpClient";
import { URL } from "../Constant/URL";

const roleList = () => {
  return httpClient.get(URL.ROLE_LIST);
};
const roleCreate = () => {
  return httpClient.get(URL.ROLE_CREATE);
};

const roleAdd = (data) => {
  return httpClient.post(URL.ROLE_ADD, data);
};

const roleUpdate = (data, id) => {
  return httpClient.post(URL.ROLE_UPDATE + id, data);
};
const roleEdit = (id) => {
  return httpClient.get(URL.ROLE_EDIT + id + "/" + "edit");
};
const roleStatus = (id) => {
  return httpClient.get(URL.STATUS_ROLE + id);
};

const RoleServices = {
  roleList,
  roleStatus,
  roleEdit,
  roleUpdate,
  roleAdd,
  roleCreate,
};

export default RoleServices;
