import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const doctorList = () => {
  return httpClient.get(URL.DOCTORS_LIST);
};

const requestedList = () => {
  return httpClient.get(URL.REQUESTED_DOCTORS);
};

const rejectedList = () => {
  return httpClient.get(URL.DOCTORS_LIST + "-rejected");
};
const viewDoctor = (id) => {
  return httpClient.get(URL.VIEW_DOCTOR + id);
};

const processDoctor = (id, status) => {
  return httpClient.get(URL.PROCESS_DOCTOR + id + "/" + status);
};

const allDoctorStatus = (id) => {
  return httpClient.get(URL.ALL_DOCTOR_STATUS + id);
};

export const doctor = {
  requestedList,
  doctorList,
  viewDoctor,
  processDoctor,
  rejectedList,
  allDoctorStatus,
};
