import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const appointmentHistory = () => {
  return httpClient.get(URL.APPOITMNENTHISTORY);
};

const rescheduleHistory = () => {
  return httpClient.get(URL.RESCHEDULEHISTORY);
};

const reschedule = (data) => {
  return httpClient.post(URL.RESCHEDULE, data);
};

const upcomingsHistory = () => {
  return httpClient.get(URL.UPCOMINGHISTORY);
};

const timeSlots = (data) => {
  return httpClient.post(URL.TIMESLOTS, data);
};

export const appointment = {
  appointmentHistory,
  upcomingsHistory,
  rescheduleHistory,
  timeSlots,
  reschedule,
};
