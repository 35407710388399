import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = ({ token }) => {
  // let auth = {'token':false}

  let temp = localStorage.getItem("hospital_Token");

  return token || temp ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoutes;
