import React, { useEffect, useState } from "react";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link, NavLink } from "react-router-dom";
import Loader from "react-loader";
import patientService, { patient } from "../Services/Service/patient";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const PatientPage = () => {
  const [loading, setLoading] = useState(true);
  const [adminData, setadminData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");

  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter(
        (val) =>
          val?.name.toLowerCase().includes(searcharr.toLowerCase()) ||
          val?.mobile.includes(searcharr)
      );
      // console.log(datas);
      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);
    let temp2 = event.selected;
    setCounter(temp2);
    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value));
  };

  const getList = () => {
    patientService
      .patientList()
      .then((res) => {
        let arr = [];

        res?.data?.patients?.map((item, ind) => {
          arr.push(item);
        });

        res?.data?.deleted_patients?.map((item, ind) => {
          arr.push({
            ...item,
            status: 7,
          });
        });

        let temp = arr.reverse();
        // console.log("temp", arr);
        setadminData(temp);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        ErrorHandler(e);
      });
  };

  const statusChange = (id) => {
    patientService
      .patienStatus(id)
      .then((res) => {
        swal(res.data.message, "", "success");
        getList();
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <aside>
      <TopHeader type="patient" />
      <article className="p-3">
        <div className="ad-btn w-100 flex-ac-jb p-2">
          {/* <Form>
            <select onChange={setListItems} name="" id="">
              <option value="10">10</option>
              <option value={adminData.length}>All</option>
            </select>
          </Form> */}
          <div></div>
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table">
            <thead>
              <tr className="text-center">
                <th>S.No</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Gender</th>
                <th>Address</th>
                <th className="text-center">Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((list, index) => (
                <tr key={index} className="text-center">
                  <td>
                    {" "}
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>
                  <td>{list.name}</td>
                  <td>{list.mobile}</td>
                  <td>{list.gender}</td>
                  <td>{list.address}</td>
                  <td className="text-center">
                    <button
                      onClick={() => statusChange(list.id)}
                      disabled={list.status == 7 ? true : false}
                      className={
                        list.status == 7
                          ? "comn-btn active-deact-btn bg-danger"
                          : list.status == 1
                          ? "comn-btn active-deact-btn bg-greens"
                          : "comn-btn active-deact-btn bg-primary1"
                      }
                    >
                      {list.status == 7
                        ? "Deleted"
                        : list.status == 1
                        ? "Active"
                        : "DeActive"}
                    </button>
                  </td>
                  <td className="flex-ac text-center">
                    <Link
                      to={"/patient/patient-details"}
                      state={{ test: "view", id: list.id, data: list }}
                      className="comn-btn bg-yellows edit-btn-svg"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-3 custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
          {items}
        </Pagination> */}
      </article>
      <Loader
        loaded={!loading}
        lines={12}
        length={12}
        width={4}
        radius={25}
        corners={2}
        rotate={0}
        direction={-1}
        color="#FF5A6A"
        speed={1}
        trail={60}
        shadow={false}
        hwaccel={true}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="55%"
        scale={1.0}
        loadedClassName="loadedContent"
      />
    </aside>
  );
};

export default PatientPage;
