import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import usePermissions from "../Services/Storage/user";
import {
  useAddroleMutation,
  useLazyRoleCreateQuery,
  useLazyRoleEditQuery,
  useRoleUpdateMutation,
} from "../redux/api/api";

const AddRoles = () => {
  const navigate = useNavigate();

  const [adminData, setadminData] = useState([]);
  const [name, setName] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  // Err
  const [nameErr, setNameErr] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const location = useLocation();

  // console.log(location?.state?.id);

  // RTK QUERY
  const [add_Role] = useAddroleMutation();
  const [role_Update] = useRoleUpdateMutation();
  const [role_Edit] = useLazyRoleEditQuery();
  const [role_Create] = useLazyRoleCreateQuery();

  useEffect(() => {
    role_Create()
      .then((res) => {
        setadminData(res.data.modules);
        // console.log(res.data);
      })
      .catch((e) => ErrorHandler(e));
  }, []);

  const notify = (error_msg) => {
    toast.error(error_msg);
  };

  const selectRole = (id) => {
    let temp = [...permissions];
    const value = id;

    const index = temp.indexOf(id);

    if (temp.includes(value)) {
      temp.splice(index, 1);
      setPermissions(temp);
    } else {
      temp.push(value);
      setPermissions(temp);
    }
    console.log(temp);
  };
  // console.log(permissions);

  const addRole = () => {
    let data = {
      role: name,
      permissions: permissions,
    };

    if (location?.state?.id) {
      data._method = "PUT";
    }
    if (name && permissions.length >= 1) {
      if (location?.state?.id) {
        role_Update({ id: location?.state?.id, payload: data })
          .unwrap()
          .then((res) => {
            console.log("role_Update");
            swal(res.message, "", "success");
            navigate("/roles");
            // <Navigate to="/roles" replace={true} state={{ from: location }} />;
            // console.log(res);
          })
          .catch((e) => {
            ErrorHandler(e);
          });
      } else {
        add_Role(data)
          .unwrap()
          .then((res) => {
            console.log("add_Role", res?.message);
            swal(res?.message, "", "success");
            navigate("/roles");
            // console.log(res);
            // <Navigate to="/roles" replace={true} state={{ from: location }} />;
          })
          .catch((e) => {
            ErrorHandler(e);
          });
      }
    } else if (!name) {
      setNameErr(true);
      notify("Please Enter Name");
    } else if (permissions.length == 0) {
      notify("Please Select  Permission");
    }
  };

  const viewRole = () => {
    role_Edit(location?.state?.id)
      .unwrap()
      .then((res) => {
        let temp = [];
        res?.permissions?.map((list) => {
          temp.push(list);
        });
        setName(res?.role?.code);
        setPermissions(temp);

        // console.log(res.data);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    if (location?.state?.id) {
      viewRole();
    }
  }, []);

  return (
    <aside>
      <TopHeader type="add-role" />
      <article className="p-3">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="col-sm-6 modal-label-input">
          <label className="text-primary-dark" htmlFor="">
            Admin Type *
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            type="text"
            id="example-if-email"
            name="example-if-email"
            placeholder="Role Name"
            value={name}
            className="bg-transparent"
          />
          {name?.length == 0 && nameErr && (
            <span className="err">Please Enter Name</span>
          )}
        </div>
        <p className="permission-text txt-primary4 mb-0 mt-0" htmlFor="">
          Permissions
        </p>
        <div className="roles-page mt-0 d-flex flex-wrap">
          {adminData?.map((list, index) => {
            return (
              <div
                key={list.id}
                className="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 pl-0">
                <h6>{list?.code}</h6>
                {list?.permissions?.map((item, index) => {
                  return (
                    <div key={item.id} className="form-check ps-0">
                      <label className="form-checks-label">
                        <input
                          checked={permissions?.includes(item.id)}
                          onClick={() => selectRole(item.id)}
                          className="form-checks-input me-2"
                          type="checkbox"
                        />
                        {item.code}
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          {/* <button className="comn-btn bg-hash2 px-3 py-2 me-2">
                Cancel
              </button> */}

          {permissions.length >= 1 && (
            <button
              onClick={addRole}
              type="button"
              className="comn-btn bg-blues px-3 py-2 me-2"
              data-dismiss="modal">
              Submit
            </button>
          )}
        </div>
      </article>
    </aside>
  );
};

export default AddRoles;
