// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { maleAvatar } from "../assets/img";
import HospitalCategories from "../component/HospitalCategories";
import PatientAppoinment from "../component/PatientAppoinment";
import Specialities from "../component/Specialities";
import TopHeader from "../component/TopHeader";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { hospital } from "../Services/Service/hospital";
import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { validEmail } from "../Services/Constant/Constant";
import {
  useHospitalUpdateMutation,
  useLazyHospitalViewQuery,
} from "../redux/api/api";
import { statesData } from "../redux/api/DummyJson";

const HospitalAdd = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const [profilePreview, setProfilePreview] = useState();
  const [licence, setLicence] = useState();
  const [licencePreview, setLicencePreview] = useState();
  const [mui, setMui] = useState();
  const [muiPreview, setMuiPreview] = useState();
  const [inputs, setInputs] = useState();
  const [err, setErr] = useState("");
  const location = useLocation();
  const [hName, setHName] = useState();
  const [aName, setAName] = useState("");
  const [uName, setUName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [hYear, setHYear] = useState("");
  const [fee, setFee] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [Street1, setStreet1] = useState("");
  const [Street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [aNumber, setANumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [gstNumber, setGSTNumber] = useState("");
  const [bankName, setBankName] = useState("");

  const [hNameErr, setHNameErr] = useState(false);
  const [aNameErr, setANameErr] = useState(false);
  const [uNameErr, setUNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [hYearErr, setHYearErr] = useState(false);
  const [feeErr, setFeeErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [Street1Err, setStreet1Err] = useState(false);
  const [Street2Err, setStreet2Err] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [businessNameErr, setBusinessNameErr] = useState("");
  const [aNumberErr, setANumberErr] = useState(false);
  const [ifscErr, setIfscErr] = useState(false);
  const [accountHolderErr, setAccountHolderErr] = useState(false);
  const [gstNumberErr, setGSTNumberErr] = useState(false);
  const [bankNameErr, setBankNameErr] = useState(false);
  const [hospitalImageErr, setHospitalImageErr] = useState(false);
  const [licenseErr, setLicenseErr] = useState(false);
  const [muiErr, setMuiErr] = useState(false);
  const [modalErr, setModalErr] = useState(false);

  console.log(location);

  const [hospital_View] = useLazyHospitalViewQuery();
  const [hospital_Update] = useHospitalUpdateMutation();

  const handlechange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  // console.log(inputs);
  console.log(pincode);
  const notify = (error_msg) => {
    toast.error(error_msg);
  };

  const viewHospital = () => {
    hospital_View(location?.state?.id)
      .then((resp) => {
        console.log(resp);
        setData(resp?.data);
      })
      .catch((err) => {
        ErrorHandler(err);
      });
  };
  useEffect(() => {
    setHName(data?.hospital?.name);
    setAName(data?.hospital?.authorized_name);
    setUName(data?.hospital?.username);
    setMobile(data?.hospital?.hospital_detail?.mobile);
    setEmail(data?.hospital?.hospital_detail?.email);
    setHYear(data?.hospital?.hospital_detail?.hospital_year);
    setFee(data?.hospital?.hospital_detail?.commission_percentage);
    setStreet1(
      data?.hospital?.bank_detail?.street_1 == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.street_1
    );
    setStreet2(
      data?.hospital?.bank_detail?.street_2 == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.street_2
    );
    setCity(
      data?.hospital?.bank_detail?.city == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.city
    );
    setState(
      data?.hospital?.bank_detail?.state == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.state
    );
    setCountry(
      data?.hospital?.bank_detail?.country == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.country
    );
    setPincode(
      data?.hospital?.bank_detail?.pin_code == 0
        ? ""
        : data?.hospital?.bank_detail?.pin_code
    );
    setBusinessName(
      data?.hospital?.bank_detail?.business_name == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.business_name
    );
    setANumber(
      data?.hospital?.bank_detail?.account_number == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.account_number
    );
    setIfsc(
      data?.hospital?.bank_detail?.ifsc_code == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.ifsc_code
    );
    setAccountHolder(
      data?.hospital?.bank_detail?.account_holder_name == "undefined"
        ? ""
        : data?.hospital?.bank_detail?.account_holder_name
    );
    setPanNumber(
      data?.hospital?.bank_detail?.pan_no == undefined
        ? ""
        : data?.hospital?.bank_detail?.pan_no
    );
    setGSTNumber(
      data?.hospital?.bank_detail?.gst_no == undefined
        ? ""
        : data?.hospital?.bank_detail?.gst_no
    );
    setDescription(data?.hospital?.hospital_detail?.description);
  }, [data]);

  useEffect(() => {
    if (location?.state?.id) {
      viewHospital();
    }
  }, []);

  const addHospitalHandler = () => {
    if (
      hName?.length == 0 ||
      aName?.length == 0 ||
      uName?.length == 0 ||
      mobile?.length == 0 ||
      // email == !validEmail ||
      email?.length == 0 ||
      hYear?.length == 0 ||
      fee?.length == 0 ||
      description?.length == 0 ||
      Street1?.length == 0 ||
      Street2?.length == 0 ||
      city?.length == 0 ||
      state?.length == 0 ||
      country?.length == 0 ||
      businessName?.length == 0 ||
      aNumber?.length == 0 ||
      ifsc?.length == 0 ||
      accountHolder?.length == 0
    ) {
      setModalErr(true);
      setTimeout(() => {
        setModalErr(false);
      }, 2000);
      setModalErr(true);
      setHNameErr(true);
      setANameErr(true);
      setUNameErr(true);
      setMobileErr(true);
      setEmailErr(true);
      setHYearErr(true);
      setFeeErr(true);
      setAddressErr(true);
      setDescriptionErr(true);
      setStreet1Err(true);
      setStreet2Err(true);
      setCityErr(true);
      setStateErr(true);
      setCountryErr(true);
      setBusinessNameErr(true);
      setANumberErr(true);
      setIfscErr(true);
      setAccountHolderErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("_method", "PUT");
      formdata.append("name", hName);
      formdata.append("authorized_name", aName);
      formdata.append("username", uName);
      formdata.append("password", password);
      formdata.append("mobile", parseInt(mobile));
      formdata.append("email", email);
      formdata.append(
        "hospital_detail_id",
        data?.hospital?.hospital_detail?.id
      );
      formdata.append("license_id", data?.hospital?.hospital_uploads[0]?.id);
      formdata.append("hospital_year", hYear);
      formdata.append("commission_percentage", parseInt(fee));
      formdata.append("address", address);
      formdata.append("description", description);
      formdata.append("street_1", Street1);
      formdata.append("street_2", Street2);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("country", country);
      formdata.append("pin_code", pincode);
      formdata.append("business_name", businessName);
      formdata.append("account_number", aNumber);
      formdata.append("ifsc_code", ifsc);
      formdata.append("account_holder_name", accountHolder);
      formdata.append("pan_no", panNumber);
      formdata.append("gst_no", gstNumber);
      formdata.append("address", data?.hospital?.hospital_detail?.address);
      formdata.append("hospital_detail_id", data?.hospital?.id);
      formdata.append("license_id", data?.hospital?.hospital_uploads[0]?.id);
      formdata.append("mui_id", data?.hospital?.hospital_uploads[1]?.id);
      if (profileImage) {
        formdata.append("image", profileImage);
      }
      if (licence) {
        formdata.append("licence", licence);
      }
      if (mui) {
        formdata.append("mui", mui);
      }
      console.log(formdata);
      hospital_Update({ id: location?.state?.id, payload: formdata })
        .unwrap()
        .then((res) => {
          console.log("resss", res?.message);
          swal(res?.message, "", "success");
          navigate("/hospital");
        })
        .catch((e) => {
          // ErrorHandler(e);
          swal(e?.message, "", "error");
          console.log("errors", e?.message);
        });
    }
  };

  const addHospital = () => {
    let formdata = new FormData();
    formdata.append("name", inputs?.name);
    formdata.append("authorized_name", inputs?.authorized_name);
    formdata.append("username", inputs?.userName);
    formdata.append("password", inputs?.password);
    formdata.append("hospital_year", inputs?.hospital_year);
    formdata.append("commission_percentage", parseInt(inputs?.commission_fee));
    formdata.append("address", inputs?.address);
    formdata.append("description", inputs?.description);
    formdata.append("email", inputs?.email);
    formdata.append("mobile", parseInt(inputs?.mobile));
    formdata.append("business_name", inputs?.business_name);
    formdata.append("street_1", inputs?.street_1);
    formdata.append("street_2", inputs?.street_2);
    formdata.append("city", inputs?.city);
    formdata.append("state", inputs?.state);
    formdata.append("pin_code", inputs?.pin_code);
    formdata.append("country", inputs?.country);
    formdata.append("account_number", inputs?.account_number);
    formdata.append("ifsc_code", inputs?.ifsc_code);
    formdata.append("account_holder_name", inputs?.account_holder_name);
    formdata.append("image", profileImage);
    formdata.append("licence", licence);
    formdata.append("mui", mui);

    hospital
      .hospitalAdd(formdata)
      .then((res) => {
        swal(res.data.message, "", "success");
        navigate("/hospital");
        // console.log(res);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };

  useEffect(() => {
    if (!mui) {
      setMuiPreview(undefined);
      return;
    }
    const profileUrlll = URL.createObjectURL(mui);
    setMuiPreview(profileUrlll);
  }, [mui]);

  useEffect(() => {
    if (!licence) {
      setLicencePreview(undefined);
      return;
    }
    const profileUrll = URL.createObjectURL(licence);
    setLicencePreview(profileUrll);
  }, [licence]);

  useEffect(() => {
    if (!profileImage) {
      setProfilePreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(profileImage);
    setProfilePreview(profileUrl);
  }, [profileImage]);

  const onClickMui = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setMui(undefined);
      return;
    }
    setMui(e.target.files[0]);
  };
  const onClickLicence = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setLicence(undefined);
      return;
    }
    setLicence(e.target.files[0]);
  };
  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setProfileImage(undefined);
      return;
    }
    setProfileImage(e.target.files[0]);
  };

  return (
    <aside className="">
      <TopHeader type="add-hospital" />
      <article>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital Name *
                    </label>
                    <input
                      onChange={(e) => {
                        setHName(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={hName}
                      id="example-if-email"
                      name="name"
                      placeholder="Enter The Hospital Name"
                      className="bg-transparent"
                      type="text"
                    />
                    {hName?.length == 0 && hNameErr && (
                      <p className="err">Enter Hospital Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Autorized Name *
                    </label>
                    <input
                      onChange={(e) => {
                        setAName(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={aName}
                      id="example-if-email"
                      name="authorized_name"
                      placeholder="Enter The Authorized Name"
                      className="bg-transparent"
                      type="text"
                    />
                    {aName?.length == 0 && aNameErr && (
                      <p className="err">Enter Authorized Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" htmlFor="">
                      UserName *
                    </label>
                    <input
                      placeholder="Enter The Username"
                      onChange={(e) => {
                        setUName(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={uName}
                      className="bg-transparent"
                      type="text"
                    />
                    {uName?.length == 0 && uNameErr && (
                      <p className="err">Enter User Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Password
                    </label>
                    <input
                      placeholder="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={password}
                      className="bg-transparent"
                      type="text"
                    />
                    {password?.length < 8 && passwordErr && (
                      <p className="err">Enter 8 digit password</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Mobile *
                    </label>
                    <input
                      onChange={(e) => {
                        setMobile(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={mobile}
                      type="number"
                      id="example-if-email"
                      placeholder="Enter The Mobile Number"
                      className="bg-transparent"
                    />
                    {mobile?.length == 0 && mobileErr && (
                      <p className="err">Enter Mobile Number</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email *
                    </label>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={email}
                      type="email"
                      id="example-if-email"
                      placeholder="Enter The Email"
                      className="bg-transparent"
                    />
                    {email?.length == 0 && emailErr && (
                      <p className="err">Enter Email</p>
                    )}
                    {email?.length > 0 && emailErr && (
                      <p className="err">Enter Valid Email</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital Year *
                    </label>
                    <input
                      onChange={(e) => {
                        setHYear(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={hYear}
                      type="date"
                      id="example-if-email"
                      placeholder="Enter The Hospital Year"
                      className="bg-transparent"
                    />
                    {hYear?.length == 0 && hYearErr && (
                      <p className="err">Select Year Hospital</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Commission Fee ( % ) *
                    </label>
                    <input
                      onChange={(e) => {
                        setFee(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={fee}
                      type="number"
                      name="commission_fee"
                      id="example-if-email"
                      placeholder="Enter The Commission Fee"
                      className="bg-transparent"
                    />
                    {fee?.length == 0 && feeErr && (
                      <p className="err">Enter Commission fee</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Street 1
                    </label>
                    <input
                      placeholder="Enter The Street 1"
                      onChange={(e) => {
                        setStreet1(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={Street1}
                      type="text"
                      name="street_1"
                      className="bg-transparent"
                    />
                    {Street1?.length == 0 && Street1Err && (
                      <p className="err">Enter Street Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Street 2
                    </label>
                    <input
                      placeholder="Ener The Street 2"
                      onChange={(e) => {
                        setStreet2(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={Street2}
                      type="text"
                      name="street_2"
                      className="bg-transparent"
                    />
                    {Street2?.length == 0 && Street2Err && (
                      <p className="err">Enter Street 2 Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      City *
                    </label>
                    <input
                      placeholder="Enter The City"
                      onChange={(e) => {
                        setCity(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={city}
                      type="text"
                      name="city"
                      className="bg-transparent"
                    />
                    {city?.length == 0 && cityErr && (
                      <p className="err">Enter City Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      State *
                    </label>
                    {/* <input
                      placeholder="Enter The State"
                      onChange={(e) => {
                        setState(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={state}
                      type="text"
                      name="state"
                      className="bg-transparent"
                    /> */}

                    <select
                      className="bg-transparent"
                      value={state}
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                    >
                      <option hidden>Select State</option>
                      {statesData.map((item, ind) => {
                        return (
                          <option value={item?.name} key={ind}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                    {state?.length == 0 && stateErr && (
                      <p className="err">Select State</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Country *
                    </label>
                    <input
                      placeholder="Enter The Country"
                      onChange={(e) => {
                        setCountry(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={country}
                      type="text"
                      name="country"
                      className="bg-transparent"
                    />
                    {country?.length == 0 && countryErr && (
                      <p className="err">Enter Country Name</p>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Pin Code
                    </label>
                    <input
                      placeholder="Pin Code"
                      onChange={(e) => {
                        setPincode(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={pincode}
                      type="number"
                      name="pin_code"
                      className="bg-transparent"
                    />
                    {pincode?.length !== 6 && pincodeErr && (
                      <p className="err">Enter pincode Mustbe 6 Digit</p>
                    )}
                  </div>
                </div>
              </div> */}
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Business Name *
                    </label>
                    <input
                      placeholder="Enter The Business Name"
                      onChange={(e) => {
                        setBusinessName(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={businessName}
                      type="text"
                      name="business_name"
                      className="bg-transparent"
                    />
                    {businessName?.length == 0 && businessNameErr && (
                      <p className="err">Enter Business Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Account Number *
                    </label>
                    <input
                      placeholder="Enter The AcountNumber"
                      onChange={(e) => {
                        setANumber(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={aNumber}
                      type="text"
                      name="account_number"
                      className="bg-transparent"
                      readOnly
                    />
                    {aNumber?.length == 0 && aNumberErr && (
                      <p className="err">Enter Account Number</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      IFSC Code *
                    </label>
                    <input
                      placeholder="Enter The IFSC Code "
                      onChange={(e) => {
                        setIfsc(e.target.value.toLocaleUpperCase());
                        console.log(e.target.value);
                      }}
                      value={ifsc}
                      type="text"
                      className="bg-transparent"
                      readOnly
                    />
                    {ifsc?.length == 0 && ifscErr && (
                      <p className="err">Enter IFSC Code</p>
                    )}
                    {ifsc?.length > 0 && ifscErr && (
                      <p className="err">Enter Valid IFSC Code</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Account Holder Name *
                    </label>
                    <input
                      placeholder="Enter The Account Holder Name "
                      onChange={(e) => {
                        setAccountHolder(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={accountHolder}
                      type="text"
                      name="account_holder_name"
                      className="bg-transparent"
                      readOnly
                    />
                    {accountHolder?.length == 0 && accountHolderErr && (
                      <p className="err">Enter account holder name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      PAN No *
                    </label>
                    <input
                      placeholder="Enter The PAN No "
                      onChange={(e) => {
                        setPanNumber(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={panNumber}
                      type="text"
                      name="pan_number"
                      className="bg-transparent"
                      readOnly
                    />
                    {/* {panNumber?.length == 0 && panNumberErr && (
                      <p className="err">Enter PAN Number</p>
                    )}
                    {panNumber?.length > 0 && panNumberErr && (
                      <p className="err">Enter Valid PAN Number</p>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="form-group col-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      GST No *
                    </label>
                    <input
                      placeholder="Enter The GST No "
                      onChange={(e) => {
                        setGSTNumber(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={gstNumber}
                      type="text"
                      name="gst_number"
                      className="bg-transparent"
                      readOnly
                    />
                    {gstNumber?.length == 0 && gstNumberErr && (
                      <p className="err">Enter GST Number</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Description *
                    </label>
                    <textarea
                      placeholder="Enter The Description"
                      onChange={(e) => {
                        setDescription(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={description}
                      type="text"
                      className="bg-transparent"
                      cols="30"
                      rows="10"
                    ></textarea>
                    {description?.length == 0 && descriptionErr && (
                      <p className="err">Enter Street Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital Image *
                    </label>
                    <input type="file" onChange={onClickImage} />

                    {!profileImage && data?.hospital?.img_url && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={data?.hospital?.img_url} />
                      </div>
                    )}
                    {profileImage && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={profilePreview} />
                      </div>
                    )}
                    {!profileImage && hospitalImageErr && (
                      <p className="err">Choose Hospital</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Licence Image *
                    </label>
                    <input type="file" onChange={onClickLicence} />{" "}
                    {!licence && licenseErr && (
                      <p className="err">Choose Licence Image</p>
                    )}
                    {!licence &&
                      data?.hospital?.hospital_uploads[0].img_url && (
                        <div className="w-90 prev-Image">
                          <img
                            className="w-100"
                            src={data?.hospital?.hospital_uploads[0].img_url}
                          />
                        </div>
                      )}
                    {licence && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={licencePreview} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      MUI *
                    </label>
                    <input type="file" onChange={onClickMui} />
                    {mui && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={muiPreview} />
                      </div>
                    )}
                    {!mui && data?.hospital?.hospital_uploads[1].img_url && (
                      <div className="w-90 prev-Image">
                        <img
                          className="w-100"
                          src={data?.hospital?.hospital_uploads[0].img_url}
                        />
                      </div>
                    )}
                    {!mui && muiErr && (
                      <p className="err">Choose Licence Image</p>
                    )}
                  </div>
                </div>
              </div>
            </Form>
            <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
              {/* <button className="comn-btn bg-hash2 px-3 py-2 me-2">
                Cancel
              </button> */}
              <button
                onClick={addHospitalHandler}
                // type="button"
                className="comn-btn bg-blues px-3 py-2 me-2"
                // data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </article>
      {/* <HospitalCategories /> */}
      {modalErr && (
        <div className="modal-err">
          <div className="in-modal-box">
            {hName?.length == 0 && hNameErr && (
              <p className="err">Hospital Name |</p>
            )}
            {aName?.length == 0 && aNameErr && (
              <p className="err"> Authorized Name |</p>
            )}
            {uName?.length == 0 && uNameErr && <p className="err">User Name</p>}
            {password?.length < 8 && passwordErr && (
              <p className="err">8 digit password |</p>
            )}
            {mobile?.length == 0 && mobileErr && (
              <p className="err">Mobile Number |</p>
            )}
            {email?.length == 0 && emailErr && (
              <p className="err">Valid Email |</p>
            )}
            {hYear?.length == 0 && hYearErr && (
              <p className="err">Select Year Hospital |</p>
            )}
            {fee?.length == 0 && feeErr && (
              <p className="err">Commission fee |</p>
            )}
            {description?.length == 0 && descriptionErr && (
              <p className="err">Description |</p>
            )}
            {Street1?.length == 0 && Street1Err && (
              <p className="err">Street Name |</p>
            )}
            {Street2?.length == 0 && Street2Err && (
              <p className="err">Street 2 Name |</p>
            )}
            {state?.length == 0 && stateErr && (
              <p className="err">State Name |</p>
            )}
            {country?.length == 0 && countryErr && (
              <p className="err">Country Name |</p>
            )}
            {/* {pincode?.length !== 6 && pincodeErr && (
              <p className="err">Enter pincode Mustbe 6 Digit |</p>
            )} */}
            {aNumber?.length == 0 && aNumberErr && (
              <p className="err">Account Number |</p>
            )}
            {ifsc?.length == 0 && ifscErr && <p className="err">IFSC Code</p>}
            {accountHolder?.length == 0 && accountHolderErr && (
              <p className="err">Account holder name |</p>
            )}
            {!profileImage && hospitalImageErr && (
              <p className="err">Choose Hospital |</p>
            )}
            {!licence && licenseErr && (
              <p className="err">Choose Licence Image |</p>
            )}
            {!mui && muiErr && <p className="err">Choose Licence Image</p>}
          </div>
        </div>
      )}
    </aside>
  );
};

export default HospitalAdd;
