// @ts-nocheck
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
let active = 3;
let items = [];
for (let number = 1; number <= 4; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}
const PatientAppoinment = ({ data }) => {
  const [appoitmentHistoryList, setAppoitmentHistoryList] = useState([]);
  // console.log(data);
  const [searcharr, setSearchArr] = useState("");
  const [listLimit, setListLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const overAllPages = Math.ceil(data?.length / listLimit);

  useEffect(() => {
    setAppoitmentHistoryList(data);
  }, [data]);
  // console.log(overAllPages);
  let pages = [];
  for (let i = 1; i <= overAllPages; i++) {
    pages.push(i);
  }
  // console.log(pages);
  const indexofLastPage = currentPage * listLimit;
  const indexofFirstPage = indexofLastPage - listLimit;
  const pagData = appoitmentHistoryList?.slice(
    indexofFirstPage,
    indexofLastPage
  );
  console.log(currentPage);

  const prevPage = () => {
    currentPage !== 1 && setCurrentPage(currentPage - 1);
  };
  const nextPage = () => {
    currentPage !== overAllPages && setCurrentPage(currentPage + 1);
  };
  const setListItems = (e) => {
    const value = e.target.value;
    setListLimit(parseInt(value));
    console.log(value);
  };
  // useEffect(() => {
  //   setListItems();
  // }, [setListItems]);

  return (
    <div className="p-3">
      <div className="ad-btn w-100 flex-ac-jb p-2">
        <Form>
          <select onChange={setListItems} name="" id="">
            <option value="10">10</option>
            <option value={appoitmentHistoryList?.length}>All</option>
          </select>
        </Form>
        <div className="flex-ac-jc">
          <Form className="search-input me-2">
            <input
              onChange={(e) => setSearchArr(e.target.value)}
              placeholder="search..."
              type="search"
              className="bg-transparent rounded-1 px-2 py-1"
            />
          </Form>
          {/* <button
            onClick={() => true}
            className="comn-btn bg-primary1 px-3 py-2"
          >
            Add patient
          </button> */}
        </div>
      </div>
      <div className="p-3 bg-white rounded-2">
        <p
          className="permission-text txt-primary4 mb-1 mt-2 text-uppercase"
          htmlFor=""
        >
          Appointment History
        </p>
        <table className="custom-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Hospital Name</th>
              <th>Patient Id</th>
              <th>Doctor</th>
              <th>Cateories</th>
              <th>Date</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {(searcharr?.toLowerCase() === "" ? pagData : appoitmentHistoryList)
              ?.filter((item) => {
                // console.log(item);
                return searcharr?.toLowerCase() === ""
                  ? item
                  : item?.speciality?.name.toLowerCase().includes(searcharr) ||
                      item?.doctor?.name.toLowerCase().includes(searcharr);
              })
              .map((list, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {(currentPage - 1) * 10 + (index + 1)}
                      {/* {index + 1} */}
                      {/* {index == 9
                        ? last
                        : counter
                        ? `${counter}${index + 1}`
                        : index + 1} */}
                    </td>
                    <td>{list?.hospital_id ? list?.hospital_id : "Nil"}</td>
                    <td className="text-center">
                      {list?.patient_id ? list?.patient_id : "-"}
                    </td>
                    <td className="text-center">{list?.doctor?.name}</td>{" "}
                    <td>{list?.speciality?.name}</td>
                    <td>{moment(list?.to_time).format("DD-MM-YYYY")}</td>
                    <td>
                      <button
                        className={
                          list.status == 6
                            ? "comn-btn active-deact-btn bg-greens"
                            : "comn-btn active-deact-btn bg-primary1"
                        }
                      >
                        {list?.status == 1 && "Incoming"}
                        {list?.status == 2 && "Initiated"}
                        {list?.status == 3 && "completed"}
                        {list?.status == 4 && "Acknowledged"}
                        {list?.status == 5 && "Not Connected"}
                        {list?.status == 6 && "Reschedule"}
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
        <div className="d-flex align-center page-item">
          <button className="page-link" onClick={prevPage}>
            {"<"}
          </button>
          {pages?.map((page, ind) => {
            return (
              <div className={currentPage == page ? "active" : null}>
                <button
                  className={
                    currentPage == page ? "active page-link" : "page-link"
                  }
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </button>
              </div>
            );
          })}
          <button className="page-link" onClick={nextPage}>
            {">"}
          </button>
        </div>
        {/* <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        /> */}
      </div>
      {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
        {items}
      </Pagination> */}
    </div>
  );
};

export default PatientAppoinment;
