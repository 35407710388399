import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../component/TopHeader";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import HospitalReport from "../component/HospitalReport";
import DoctorReport from "../component/DoctorReport";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import reportServices from "../Services/Service/report";
import { Link } from "react-router-dom";
import swal from "sweetalert";

const ReportPage = () => {
  const [downloadModal, setDownloadModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [fromerr, setFromerr] = useState(false);
  const [toErr, setToErr] = useState(false);
  const [urlDownload, setUrlDownload] = useState("");
  const [chooseFile, setChooseFile] = useState();
  const [fileFormat, setFileFormat] = useState("");
  console.log(fileFormat);
  useEffect(() => {
    let fileName = fileFormat.split(".").pop();
    console.log(fileName);
  }, [fileFormat]);
  console.log("urlDownload", urlDownload);
  const uploadShowHandler = () => {
    setUploadModal(!uploadModal);
  };
  const downloadShowHandler = () => {
    setDownloadModal(!downloadModal);
  };

  console.log(moment(fromDate).format("YYYY-MM-DD"));
  console.log(moment(toDate).format("YYYY-MM-DD"));

  const uploadHandler = () => {
    if (fileFormat.split(".").pop() == "xlsx") {
      let formdata = new FormData();
      formdata.append("excel", chooseFile);
      reportServices
        .upload_Report(formdata)
        .then((res) => {
          console.log(res);

          swal(res?.data?.status);
          if (res?.data?.status == "success") window.location.reload(false);
        })
        .catch((err) => err);
      console.log("098");
    } else {
      alert("Xlsx format only accepted");
    }
  };

  let fromdDD = moment(fromDate).format("DD");
  let fromMM = moment(fromDate).format("MM");
  let fromYYY = moment(fromDate).format("YYY");
  let toDD = moment(toDate).format("DD");
  let toMM = moment(toDate).format("MM");
  let toYYY = moment(toDate).format("YYY");

  const downloadHandler = () => {
    if (fromDate && toDate) {
      console.log("ww");
      if (fromYYY <= toYYY) {
        if (fromMM <= toMM) {
          if (fromdDD < toDD) {
            let formdata = new FormData();
            formdata.append("from_date", moment(fromDate).format("YYYY-MM-DD"));
            formdata.append("to_date", moment(toDate).format("YYYY-MM-DD"));
            console.log(formdata);
            reportServices
              .download_Report(formdata)
              .then((res) => {
                setUrlDownload(res?.data?.url);
                // console.log(res?.data?.url);
              })
              .catch((err) => err);
          } else {
            alert("Need for from date is less then to Date");
          }
        } else {
          alert("Need for from month is less then to Date");
        }
      } else {
        alert("Need for from year is less then to year");
      }
    } else {
      setFromerr(true);
      setToErr(true);
    }
  };

  return (
    <aside>
      <TopHeader type="report" />
      <article className="p-3 position-relative">
        <Tabs
          defaultActiveKey="hospital-fee"
          id="fill-tab-example"
          className="tab-appoinment"
          fill>
          <Tab eventKey="hospital-fee" title="Hospital">
            <HospitalReport />
          </Tab>
          <Tab eventKey="doctor-fee" title="Doctor">
            <DoctorReport />
          </Tab>
        </Tabs>
        <div
          className="position-absolute"
          style={{ top: "25px", right: "30px" }}>
          {/* <button
            onClick={uploadShowHandler}
            className="comn-btn bg-greens py-1 px-2 mx-1"
          >
            <FontAwesomeIcon className="me-1" icon={faUpload} />
            Upload
          </button> */}
          <button
            onClick={downloadShowHandler}
            className="comn-btn bg-primary2 py-1 px-2 mx-1">
            <FontAwesomeIcon className="me-1" icon={faDownload} />
            Download
          </button>
        </div>
        <Modal
          className="table-modal"
          show={uploadModal}
          onHide={uploadShowHandler}
          aria-labelledby="example-modal-sizes-title-lg">
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>Report Upload</h4>
            </div>
            <div className="d-flex col-12">
              <div className="px-1 col-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  File Upload
                </label>
                <input
                  type="file"
                  onChange={(e) => {
                    setChooseFile(e.target.files[0]);
                    setFileFormat(e.target.files[0].name);
                  }}
                  value={fromDate}
                />
                {fromerr && !fromDate && (
                  <p className="err">Please choose from date</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={uploadHandler}
              className="comn-btn bg-primary1 px-3 py-2 me-2">
              Submit
            </button>
          </div>
        </Modal>
        <Modal
          className="table-modal"
          show={downloadModal}
          onHide={downloadShowHandler}
          aria-labelledby="example-modal-sizes-title-lg">
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>Report Download</h4>
            </div>
            <div className="d-flex col-12">
              <div className="px-1 col-6 modal-label-input">
                <label className="text-primary-dark" for="">
                  From
                </label>
                <input
                  type="date"
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                />
                {fromerr && !fromDate && (
                  <p className="err">Please choose from date</p>
                )}
              </div>
              <div className="px-1 col-6 modal-label-input">
                <label className="text-primary-dark" for="">
                  To
                </label>
                <input
                  type="date"
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                />
                {toErr && !toDate && (
                  <p className="err">Please choose to date</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            {urlDownload && (
              <>
                <a
                  className="comn-btn bg-greens px-3 py-2 me-2"
                  target="_blank"
                  // href="https://pariseva.tallytraininginchennai.in/public/payment-export/2023-02-08/2023-02-21">
                  href={urlDownload}>
                  Download
                </a>
                <buttton
                  className="comn-btn bg-hash2 px-3 py-2 me-2"
                  onClick={() => window.location.reload(false)}>
                  close
                </buttton>
              </>
              // <Link
              //   onClick={() => window.location.reload(false)}
              //   className="comn-btn bg-greens px-3 py-2 me-2"
              //   style={{ textDecoration: "none" }}
              //   to="https://pariseva.tallytraininginchennai.in/public/payment-export/2023-02-08/2023-02-21"
              // target="_blank"
              //   download
              // >
              //   <FontAwesomeIcon className="me-2" icon={faDownload} />
              //   Download
              // </Link>
            )}
            {!urlDownload && (
              <button
                onClick={downloadHandler}
                className="comn-btn bg-primary1 px-3 py-2 me-2">
                Submit
              </button>
            )}
          </div>
        </Modal>
      </article>
    </aside>
  );
};

export default ReportPage;
