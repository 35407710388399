import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const adminList = () => {
  return httpClient.get(URL.ADMIN_NOTIFICATION_LIST);
};
const hospitalList = () => {
  return httpClient.get(URL.HOSPITAL_NOTIFICATION_LIST);
};
const adminReadClick = () => {
  return httpClient.get(URL.ADMIN_READ_CLICK);
};
const readStatus = () => {
  return httpClient.get(URL.READ_STATUS);
};

export const notification = {
  adminList,
  hospitalList,
  adminReadClick,
  readStatus,
};
