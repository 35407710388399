import {
  faBell,
  faCalendar,
  faHospital,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBed,
  faCoins,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col } from "react-bootstrap";
import TopHeader from "../component/TopHeader";
import TopHeader1 from "../component/TopHeader1";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { dashBoardServices } from "../Services/Service/dashBoardServices";

const HHome = () => {
  const [dashBoard, setDashBoard] = useState();

  useEffect(() => {
    dashBoardServices
      .adminDashBoard()
      .then((res) => {
        setDashBoard(res.data);
      })
      .catch((e) => ErrorHandler(e));
  }, []);

  return (
    <aside>
      <TopHeader1 type="crm-dashboard" />
      <article className="d-flex flex-wrap p-2">
        <Col xs={6} sm={4} lg={3} className="p-2">
          <div className="dash-box px-3 py-2">
            <div className="">
              <button className="bg-secondary-gradient">
                <FontAwesomeIcon icon={faUserDoctor} />
              </button>
              <h6 className="text-start">Total</h6>
              <h5 className="text-start">Doctor</h5>
            </div>
            <h3 className="text-end text-">890</h3>
          </div>
        </Col>
        <Col xs={6} sm={4} lg={3} className="p-2">
          <div className="dash-box px-3 py-2">
            <div className="">
              <button className="bg-danger-gradient">
                <FontAwesomeIcon icon={faBed} />
              </button>
              <h6 className="text-start">Total</h6>
              <h5 className="text-start">Patients</h5>
            </div>
            <h3 className="text-end text-">{dashBoard?.patients}</h3>
          </div>
        </Col>
        <Col xs={6} sm={4} lg={3} className="p-2">
          <div className="dash-box px-3 py-2">
            <div className="">
              <button className="bg-success-gradient">
                <FontAwesomeIcon icon={faCalendar} />
              </button>
              <h6 className="text-start">Total</h6>
              <h5 className="text-start">Appointment</h5>
            </div>
            <h3 className="text-end text-">{dashBoard?.appointments}</h3>
          </div>
        </Col>
        <Col xs={6} sm={4} lg={3} className="p-2">
          <div className="dash-box px-3 py-2">
            <div className="">
              <button className="bg-prim-gradient">
                <FontAwesomeIcon icon={faCoins} />
              </button>
              <h6 className="text-start">Total</h6>
              <h5 className="text-start">Revenue</h5>
            </div>
            <h3 className="text-end text-">{dashBoard?.total_revenue}</h3>
          </div>
        </Col>
        <Col xs={6} sm={4} lg={3} className="p-2">
          <div className="dash-box px-3 py-2">
            <div className="">
              <button className="bg-primary-gradient">
                <FontAwesomeIcon icon={faStar} />
              </button>
              <h6 className="text-start">Cumulative</h6>
              <h5 className="text-start">Ratings</h5>
            </div>
            <h3 className="text-end text-">{dashBoard?.total_revenue}</h3>
          </div>
        </Col>
      </article>
      {/* <Col className="py-2 px-2">
        <Col xs={12} md={6} lg={6} className="px-2">
          <div className="bg-background1 form-group col-12 ps-0 rounded-2 px-2 py-2">
            <div className="form-check ps-0 ">
              <div className="px-2 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Doctor Commission(%)
                </label>
                <input
                  type="number"
                  id="example-if-email"
                  name="example-if-email"
                  placeholder="Name"
                  value="10"
                  className="bg-transparent"
                  disabled
                />
              </div>
            </div>
            <div className="col-xs-12 d-flex justify-content-end px-2 mb-2">
              <button className="comn-btn bg-primary1 px-3 py-2 me-0">
                Change Fee
              </button>
              <button
                type="button"
                className="comn-btn bg-blues px-3 py-2 me-2"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </Col>
      </Col> */}
    </aside>
  );
};

export default HHome;
