import httpClient from "../Constant/httpClient";
import { URL } from "../Constant/URL";

const adminList = () => {
  return httpClient.get(URL.ADMIN_LIST);
};

const adminCreate = () => {
  return httpClient.get(URL.ADMIN_CREATE);
};

const adminAdd = (data) => {
  return httpClient.post(URL.ADMIN_ADD, data);
};

const adminUpdate = (data, id) => {
  return httpClient.post(URL.ADMIN_UPDATE + id, data);
};
const adminEdit = (id) => {
  return httpClient.get(URL.ADMIN_EDIT + id + "/" + "edit");
};
const adminStatus = (id) => {
  return httpClient.get(URL.STATUS_ADMIN + id);
};
const AdminServices = {
  adminList,
  adminStatus,
  adminCreate,
  adminAdd,
  adminEdit,
  adminUpdate,
};

export default AdminServices;
