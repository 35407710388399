import axios from "axios";

import { BASE_URL } from "./URL";
export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    Accept: "application/json",
  },
});
