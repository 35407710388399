import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const categoriesList = () => {
  return httpClient.get(URL.CATEOGRIES_LIST);
};

const addcategories = (data) => {
  return httpClient.post(URL.CATEOGRIES_LIST, data);
};

const viewcategories = (id) => {
  return httpClient.get(URL.CATEOGRIES_VIEW + id);
};

const editcategories = (id, data) => {
  return httpClient.post(URL.CATEOGRIES_VIEW + id, data);
};

const statuscategories = (id) => {
  return httpClient.get(URL.CATEOGRIES_VIEW + "status" + "/" + id);
};

export const categories = {
  categoriesList,
  addcategories,
  viewcategories,
  editcategories,
  statuscategories,
};
