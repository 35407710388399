import React, { useEffect, useState } from "react";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link, NavLink } from "react-router-dom";
import patientService, { patient } from "../Services/Service/patient";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { notification } from "../Services/Service/notification";

const NotificationPage = () => {
  const [adminData, setadminData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");

  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.title.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  const getList = () => {
    notification
      .adminList()
      .then((res) => {
        setadminData(res?.data?.notifications);
        console.log(res);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };

  const statusChange = () => {
    notification
      .adminList()
      .then((resp) => {
        // setadminData(resp?.data)
        console.log(resp);
        // swal(res.data.message, "", "success");
        getList();
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <aside>
      <TopHeader type="notification" />
      <article className="p-3">
        <div className="ad-btn w-100 flex-ac-jb p-2">
          {/* <Form>
            <select onChange={setListItems} name="" id="">
              <option value="10">10</option>
              <option value={adminData.length}>All</option>
            </select>
          </Form> */}
          <div></div>
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table_new">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Description</th>
                {/* <th>Date</th> */}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((list, index) => (
                <tr key={index}>
                  <td>
                    {" "}
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>

                  <td>{list.title}</td>
                  <td>{list.description}</td>
                  {/* <td>{list.description}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
          {items}
        </Pagination> */}
      </article>
    </aside>
  );
};

export default NotificationPage;
