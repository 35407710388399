import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { bannerList, maleAvatar } from "../assets/img";
import HospitalCategories from "../component/HospitalCategories";
import PatientAppoinment from "../component/PatientAppoinment";
import Specialities from "../component/Specialities";
import TopHeader from "../component/TopHeader";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { hospital } from "../Services/Service/hospital";
import usePermissions from "../Services/Storage/user";
import { useLazyHospitalViewQuery } from "../redux/api/api";

const HospitalDetailcopy = () => {
  const { user } = usePermissions();
  const [profileImage, setProfileImage] = useState();
  const [profilePreview, setProfilePreview] = useState();
  const [licence, setLicence] = useState();
  const [list, setList] = useState();
  const [licencePreview, setLicencePreview] = useState();
  const [muiPreview, setMuiPreview] = useState();
  const location = useLocation();
  const [inputs, setInputs] = useState();
  const [hospitalDoctor, setHospitalDoctor] = useState([]);

  const [hospitalList, setHospitalList] = useState("");
  const [hospital] = useLazyHospitalViewQuery();

  useEffect(() => {});

  const handlechange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const viewHospital = () => {
    hospital(location?.state?.id)
      .unwrap()
      .then((res) => {
        console.log("location?.state?.id", res);
        setInputs(res?.hospital);
        setHospitalDoctor(res?.data?.hospital_doctors);
        setProfilePreview(res?.hospital?.img_url);
        setLicencePreview(res?.hospital?.hospital_uploads[0]?.img_url);
        setMuiPreview(res?.hospital?.hospital_uploads[1]?.img_url);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    if (location?.state?.id) {
      viewHospital();
    }
  }, []);

  useEffect(() => {
    if (!licence) {
      setLicencePreview(undefined);
      return;
    }
    const profileUrll = URL.createObjectURL(licence);
    setLicencePreview(profileUrll);
  }, [licence]);

  useEffect(() => {
    if (!profileImage) {
      setProfilePreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(profileImage);
    setProfilePreview(profileUrl);
  }, [profileImage]);

  const onClickLicence = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setLicence(undefined);
      return;
    }
    setLicence(e.target.files[0]);
  };

  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setProfileImage(undefined);
      return;
    }
    setProfileImage(e.target.files[0]);
  };

  console.log("inputs?.password", inputs?.password);
  return (
    <aside className="">
      <TopHeader type="hospital-detail" />
      <article>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <div className="flex-ac-jb">
              <p
                className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                for="">
                {inputs?.name}
              </p>
              <img
                className="avatar-img"
                src={profilePreview ? profilePreview : maleAvatar}
              />
            </div>
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Autorized Name
                    </label>
                    <input
                      disabled
                      onChange={handlechange}
                      type="text"
                      id="example-if-email"
                      value={inputs?.authorized_name}
                      name="authorized_name"
                      placeholder="Autorized Name"
                      // value="Niompin"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital Name
                    </label>
                    <input
                      disabled
                      onChange={handlechange}
                      type="Name"
                      id="example-if-email"
                      value={inputs?.name}
                      name="name"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      UserName
                    </label>
                    <input
                      disabled
                      value={inputs?.username}
                      onChange={handlechange}
                      name="userName"
                      className="bg-transparent"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Password
                    </label>
                    <input
                      value={inputs?.password}
                      onChange={handlechange}
                      name="password"
                      className="bg-transparent"
                      type="password"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Mobile
                    </label>
                    <input
                      disabled
                      value={inputs?.hospital_detail?.mobile}
                      onChange={handlechange}
                      name="mobile"
                      type="Name"
                      id="example-if-email"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email
                    </label>
                    <input
                      disabled
                      value={inputs?.hospital_detail?.email}
                      type="email"
                      onChange={handlechange}
                      name="email"
                      id="example-if-email"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital Year
                    </label>
                    <input
                      disabled
                      value={inputs?.hospital_detail?.hospital_year}
                      onChange={handlechange}
                      name="hospital_year"
                      type="date"
                      id="example-if-email"
                      placeholder="Name"
                      // value="12-09-1999"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Commission Fee ( % )
                    </label>
                    <input
                      disabled
                      onChange={handlechange}
                      value={inputs?.hospital_detail?.commission_percentage}
                      name="commission_fee"
                      // type="date"
                      id="example-if-email"
                      placeholder="Name"
                      // value="80"
                      className="bg-transparent"
                    />
                    {/* <select name="" id="" disabled>
                      <option value="Active">Active</option>
                      <option value="Deactive">Deactive</option>
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Street - 1
                    </label>
                    <p className="address-request">
                      {inputs?.bank_detail?.street_1}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Street - 2
                    </label>
                    <p className="address-request">
                      {inputs?.bank_detail?.street_2}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      State
                    </label>
                    <p className="address-request">
                      {inputs?.bank_detail?.state}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      City
                    </label>
                    <p className="address-request">
                      {inputs?.bank_detail?.country}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Pincode
                    </label>
                    <p className="address-request">
                      {inputs?.bank_detail?.pin_code}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Business Name
                    </label>
                    <p className="address-request">
                      {inputs?.bank_detail?.business_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      AcountNumber
                    </label>
                    <input
                      disabled
                      value={inputs?.bank_detail?.account_number}
                      type="text"
                      onChange={handlechange}
                      name="acountnumber"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Account Holder Name
                    </label>
                    <input
                      disabled
                      value={inputs?.bank_detail?.account_holder_name}
                      type="text"
                      onChange={handlechange}
                      name="acountnumber"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      IFSC Code
                    </label>
                    <input
                      disabled
                      value={inputs?.bank_detail?.ifsc_code}
                      onChange={handlechange}
                      name="ifsc_code"
                      className="bg-transparent"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-12 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Address
                    </label>
                    <p className="address-request">
                      98/09 Jiuoyn Nagar, Koiuyj lads, Us - 8900"
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Description
                    </label>
                    <p className="address-request">
                      {inputs?.hospital_detail?.description}
                    </p>
                  </div>
                </div>
              </div>
              {profilePreview ? (
                <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Hospital Image
                      </label>

                      <Image className="w-90" src={profilePreview} />
                    </div>
                  </div>
                </div>
              ) : null}
              {licencePreview ? (
                <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Licence Image
                      </label>
                      <Image className="w-90" src={licencePreview} />
                    </div>
                  </div>
                </div>
              ) : null}
              {muiPreview ? (
                <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        MUI Image
                      </label>
                      <Image className="w-90" src={muiPreview} />
                    </div>
                  </div>
                </div>
              ) : null}
            </Form>
            {user.includes("CAN-EDIT-HOSPITALS") ? (
              <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2 mt-3">
                <Link
                  state={{ id: location?.state?.id }}
                  to="/hospital/hospital-edit"
                  className="comn-btn bg-blues px-3 py-2 me-2 text-decoration-none">
                  Edit
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </article>
      {hospitalDoctor?.length && <HospitalCategories data={hospitalDoctor} />}
    </aside>
  );
};

export default HospitalDetailcopy;
