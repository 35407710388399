import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Col, Form, Modal, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, Navigate, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { appointment } from "../Services/Service/appoinment";

const AppointmentRequest = ({ adminData, reloadBtn, setReload }) => {
  // console.log(adminData);
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");
  const [show, setShow] = useState(false);
  const [dateSlot, setDateSlot] = useState("");
  const [doctor_id, setDoctor_id] = useState("");
  const [timeSlot, setSetTimeSlot] = useState("");
  const [timeChooseSlot, setTimeChooseSlot] = useState();
  const [timeSlotid, setTimeSlotid] = useState();
  const [scheduleId, setScheduleId] = useState();
  const [appointId, setAppointId] = useState();
  // console.log(scheduleId, appointId);
  // console.log(timeSlot);

  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter(
        (val) =>
          val?.doctor?.name.toLowerCase().includes(searcharr.toLowerCase()) ||
          val?.speciality?.name.toLowerCase().includes(searcharr.toLowerCase())
      );
      setCurrentItems(datas);
    }
  }, [searcharr]);

  const datesArray = [];
  let date = new Date();
  for (let i = 0; i < 7; i++) {
    date = new Date(date.setDate(date.getDate() + 1));
    datesArray.push(date + 1);
  }
  // console.log(datesArray);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    // console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value));
  };

  useEffect(() => {
    slotHandler();
  }, [dateSlot]);

  const slotHandler = () => {
    let payload = {
      date: dateSlot,
      doctor_id: doctor_id,
    };
    // console.log(payload);
    // console.log(dateSlot);
    if (doctor_id) {
      appointment
        .timeSlots(payload)
        .then((res) => {
          setSetTimeSlot(res?.data?.doctor_slots);
        })
        .catch((e) => {
          ErrorHandler(e);
        });
    }
  };
  const handleShow = (e) => {
    setDoctor_id(e);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const rescheduleHandler = () => {
    setShow(false);
    let payload = {
      schedule_slot_id: scheduleId,
      appointment_id: appointId,
    };
    // console.log(payload);
    appointment
      .reschedule(payload)
      .then((resp) => {
        // console.log(resp?.data);
        if (resp?.data?.status == "success") {
          // console.log(resp?.data);
          navigate("/appointment");
          swal(resp?.data?.message, "", "success");
          // reloadBtn();
          setReload(true);
        }
      })
      .catch((e) => {
        // ErrorHandler(e);
      });
  };

  const verify = localStorage?.getItem("hospital_Token");
  return (
    <>
      <div className="ad-btn w-100 flex-ac-jb p-2">
        {/* <Form>
          <select onChange={setListItems} name="" id="">
            <option value="10">10</option>
            <option value={adminData.length}>All</option>
          </select>
        </Form> */}
        <div />
        <div className="flex-ac-jc">
          <Form className="search-input me-2">
            <input
              onChange={(e) => setSearchArr(e.target.value)}
              placeholder="search..."
              type="search"
              className="bg-transparent rounded-1 px-2 py-1"
            />
          </Form>
        </div>
      </div>

      <div className="p-2 bg-white rounded-2">
        {/* <p
          className="permission-text txt-primary4 mb-1 mt-0 text-uppercase"
          for=""
        >
          Upcoming
        </p> */}
        <table className="custom-table">
          <thead>
            <tr className="text-center text-nowrap">
              <th>S.No</th>
              <th>Patient</th>
              <th>Doctor</th>
              <th>Hospital</th>
              <th>Speciality</th>
              <th>Date</th>
              <th>Time</th>
              {/* <th>End Time</th> */}
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((list, index) => (
              <tr key={index} className="text-center text-nowrap">
                <td>{index + 1}</td>
                <td>{list?.patient?.name}</td>
                <td>{list?.doctor?.name}</td>
                <td>
                  {list?.hospital?.name
                    ? list?.hospital?.name
                    : "Individual Doctor"}
                </td>
                <td>{list?.doctor?.username}</td>
                <td>{moment(list?.from_time).format("DD-MM-YYYY")}</td>
                <td>
                  {moment(list?.from_time).format("hh:ss a") +
                    " to " +
                    moment(list?.to_time).format("hh:ss a")}
                </td>
                {/* <td>{moment(list?.to_time).format("hh:ss a")}</td> */}
                <td className="text-center">
                  <button
                    // onClick={() =>
                    //   list.id == 2 ? handleShow() : setAddModal(true)
                    // }
                    // className={
                    //   list.status == "Finished"
                    //     ? "comn-btn active-deact-btn bg-greens"
                    //     : "comn-btn active-deact-btn bg-primary1"
                    // }
                    className="comn-btn active-deact-btn bg-primary1"
                    onClick={() => {
                      handleShow(list?.doctor_id);
                      setAppointId(list?.id);
                    }}
                  >
                    {/* {list.status} */}
                    Reschedule
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Reschedule
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Row>
                {/* <Col sm={6}>
                  <div className="form-group  ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Patient
                        </label>
                        <input
                          // onChange={handlechange}
                          name="ifsc_code"
                          className="bg-transparent"
                          type="text"
                          // value="HDFC14111"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group  ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Duration
                        </label>
                        <input
                          // onChange={handlechange}
                          name="ifsc_code"
                          className="bg-transparent"
                          type="text"
                          // value="HDFC14111"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group  ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Doctor
                        </label>
                        <input
                          // onChange={handlechange}
                          name="ifsc_code"
                          className="bg-transparent"
                          type="text"
                          // value="HDFC14111"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group  ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Appointment Fee
                        </label>
                        <input
                          // onChange={handlechange}
                          name="ifsc_code"
                          className="bg-transparent"
                          type="text"
                          // value="HDFC14111"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col sm={12}>
                      <h6>Available Dates</h6>
                    </Col>
                  </Row>
                </Col> */}
                <Col sm={12}>
                  {datesArray.map((list, i) => {
                    return (
                      <button
                        onClick={() => {
                          setDateSlot(moment(list).format("YYYY-MM-DD"));
                          setTimeSlotid(i);
                          // console.log(i);
                        }}
                        className={
                          timeSlotid == i ? "chooseDate" : "choosedDate"
                        }
                      >
                        {moment(list).format("DD-MM-YYYY")}
                      </button>
                    );
                  })}
                </Col>
                {timeSlot[0] && (
                  <Col sm={12} className="my-1">
                    <h6>Morning Slot</h6>
                    <div>
                      {timeSlot[0]?.slots?.map((list, i) => {
                        return (
                          <button
                            onClick={() => {
                              // setScheduleId(list?.doctor_schedule_id);
                              setScheduleId(list?.id);
                              setTimeChooseSlot(i + "AM");
                              // setAppointId(list?.id);
                              // console.log(i);
                              // console.log(list);
                              // setTimeChooseSlot({ index: i, type: "AM" });
                            }}
                            className={
                              timeChooseSlot == i + "AM"
                                ? "chooseTime"
                                : "choosedTime"
                            }
                          >
                            {moment(list?.from_time).format("hh:ssa")}
                            {" - "}
                            {moment(list?.to_time).format("hh:ssa")}
                          </button>
                        );
                      })}
                    </div>
                  </Col>
                )}
                {timeSlot[1] && (
                  <Col sm={12} className="my-1">
                    <h6>Evening Slot</h6>
                    <div>
                      {timeSlot[1]?.slots?.map((list, i) => {
                        return (
                          <button
                            onClick={() => {
                              setScheduleId(list?.id);
                              setTimeChooseSlot(i);
                              // setTimeChooseSlot({ index: i, type: "PM" });
                              // setAppointId(list?.id);
                              // console.log(i);
                            }}
                            className={
                              timeChooseSlot == i ? "chooseTime" : "choosedTime"
                            }
                          >
                            {moment(list?.from_time).format("hh:ssa")}
                            {" - "}
                            {moment(list?.to_time).format("hh:ssa")}
                          </button>
                        );
                      })}
                    </div>
                  </Col>
                )}
              </Row>
            </>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={handleClose}>Close</Button> */}
            <button
              onClick={handleClose}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Cancel
            </button>
            <button
              onClick={rescheduleHandler}
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default AppointmentRequest;
