import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const supportList = () => {
  return httpClient.get(URL.SUPPORT_LIST);
};

const raiseSupport = (data) => {
  return httpClient.post(URL.HOSPITAL_SUPPORT, data);
};

const hospitalSupportList = () => {
  return httpClient.get(URL.HOSPITAL_SUPPORT_LIST);
};

const viewSupport = (id) => {
  return httpClient.get(URL.VIEW_SUPPORT + id);
};

const replySupport = (data) => {
  return httpClient.post(URL.REPLY_SUPPORT, data);
};

const replyiedSupport = () => {
  return httpClient.get(URL.ANSWERED_SUPPORT);
};

const hospitalReplyiedSupport = () => {
  return httpClient.get(URL.HOSPITAL_SUPPORT_REPLYIED);
};

export const supportServices = {
  hospitalSupportList,
  raiseSupport,
  supportList,
  viewSupport,
  replySupport,
  replyiedSupport,
  hospitalReplyiedSupport,
};
