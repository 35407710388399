// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../assets/img";
import Navbar from "../navbar/Navbar";
import Navbar1 from "../navbar/Navbar1";

const Header = () => {
  return (
    <header className="menu-bar">
      <Link to="/" className="top-head">
        <img src={Logo} className="logo" />
        <h5 className="app-name">Pariseva</h5>
      </Link>
      <div className="btm-header-rit-top"></div>
      <Navbar />
    </header>
  );
};

export default Header;
