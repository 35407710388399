export const adminIcon = require("./icon/admin.png");
export const dashbaordIcon = require("./icon/dashboard.png");
export const roleIcon = require("./icon/role.png");
export const patientIcon = require("./icon/patient.png");
export const hospitalIcon = require("./icon/hospital.png");
export const doctorIcon = require("./icon/doctor.png");
export const categoryIcon = require("./icon/categories.png");
export const BannerIcon = require("./icon/banner.png");
export const AppointmentIcon = require("./icon/appointment.png");
export const RequestIcon = require("./icon/request.png");
export const FaqIcon = require("./icon/faq.png");
export const NotifIcon = require("./icon/notif.png");
export const HelpIcon = require("./icon/help.png");
export const TermsCondiIcon = require("./icon/terms.png");
export const PrivacyIcon = require("./icon/privacy.png");
export const Logout = require("./icon/logout.png");
export const maleAvatar = require("./icon/man.png");
export const femaleAvatar = require("./icon/woman.png");
export const bannerList = require("./banner1.jpg");
export const Logo = require("./pariseva.png");
export const LoginLeft = require("./login.png");
export const eyeICon = require("./icon/eye.png");
export const eyeICon1 = require("./icon/eye1.png");
