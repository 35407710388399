import React from "react";
import {
  faBell,
  faBellSlash,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import { LoginServices } from "../Services/Service/login";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import swal from "sweetalert";
import { notification } from "../Services/Service/notification";
import { useEffect } from "react";
import { useState } from "react";
import { useLazyLogoutQuery } from "../redux/api/api";

const TopHeader = (props) => {
  const [status, setstatus] = useState(false);
  const locations = useLocation();
  const navigate = useNavigate();

  // RTK Query

  const [logout] = useLazyLogoutQuery();

  const logoutAccount = () => {
    console.log("Logout");
    logout()
      .unwrap()
      .then((res) => {
        console.log("res", res);
        if (res?.status == "success") {
          navigate("/signin");
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 400);
        }
      });
  };

  const logoutAccount1 = () => {
    navigate("/signin");
    localStorage.clear();
    // LoginServices.logout()
    //   .then((res) => {
    //     swal(res.data.message, "", "success");
    //     setTimeout(() => {
    //       navigate("/signin");
    //       localStorage.clear();
    //       // locations.reload();
    //     }, 2000);
    //   })
    //   .catch((e) => ErrorHandler(e));
    window.location.href = "/";
  };

  const notificationHandler = () => {
    navigate("/notification");
    notification
      .adminReadClick()
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => err);
  };

  const statusAPi = () => {
    notification
      .readStatus()
      .then((resp) => {
        console.log(resp);
        setstatus(resp?.data?.read_status);
      })
      .catch((err) => err);
  };
  useEffect(() => {
    statusAPi();
  }, []);

  return (
    <div className="">
      <div className="flex-ac-jb header-rit-top px-4 py-2">
        <div className="">
          {props.type == "dashboard" && <h4>Dashboard</h4>}
          {props.type == "admin" && <h4>Admin</h4>}
          {props.type == "role" && <h4>Roles</h4>}
          {props.type == "add-role" && (
            <div className="">
              <h4>Add Roles</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <a href="#">
                    <FontAwesomeIcon icon={faHome} /> Roles
                  </a>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Roles</li>
              </ul>
            </div>
          )}
          {props.type == "patient" && <h4>Patients</h4>}
          {props.type == "doctor" && <h4>DOctors</h4>}
          {props.type == "doctor-request" && (
            <div className="">
              <h4>Doctor Request</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/patient">
                    <FontAwesomeIcon icon={faHome} /> Doctor
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Request</li>
              </ul>
            </div>
          )}
          {props.type == "doctor-detail" && (
            <div className="">
              <h4>Doctor Details</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/doctor">
                    <FontAwesomeIcon icon={faHome} /> Doctor
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Doctor Details</li>
              </ul>
            </div>
          )}
          {props.type == "patient-detail" && (
            <div className="">
              <h4>Patient Details</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/patient">
                    <FontAwesomeIcon icon={faHome} /> Patients
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Patients Details</li>
              </ul>
            </div>
          )}
          {props.type == "hospital" && <h4>Hospitals</h4>}
          {props.type == "add-hospital" && (
            <div className="">
              <h4>Add Hospital</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/hospital">
                    <FontAwesomeIcon icon={faHome} /> Hospitals
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Hospital</li>
              </ul>
            </div>
          )}
          {props.type == "hospital-detail" && (
            <div className="">
              <h4>Hospital Details</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/hospital">
                    <FontAwesomeIcon icon={faHome} /> Hospitals
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Hospital Details</li>
              </ul>
            </div>
          )}
          {props.type == "hospital-adit" && (
            <div className="">
              <h4>Hospital Edit</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/hospital">
                    <FontAwesomeIcon icon={faHome} /> Hospitals
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Hospital Edit</li>
              </ul>
            </div>
          )}
          {props.type == "categories" && <h4>Speciality</h4>}
          {props.type == "banner" && <h4>Banners</h4>}
          {props.type == "appointment" && <h4>Appoinments</h4>}
          {props.type == "faq" && <h4>FAQ</h4>}
          {props.type == "help-support" && <h4>Help & Support</h4>}
          {props.type == "report" && <h4>Report</h4>}
          {props.type == "notication" && <h4>Notification</h4>}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Dropdown>
            <div className="header-right d-flex d-grid">
              <Dropdown.Toggle
                className="border-0 d-grid"
                style={{ background: "transparent" }}
                id="dropdown-basic">
                <a className="bg-dark text-light">
                  <FontAwesomeIcon icon={faUser} />
                </a>
              </Dropdown.Toggle>
            </div>
            <Dropdown.Menu>
              <Link
                onClick={logoutAccount}
                style={{ textDecoration: "none" }}
                to="#">
                <Dropdown.Item style={{ textDecoration: "none" }}>
                  Logout
                </Dropdown.Item>
              </Link>

              <Dropdown.Item>
                <Link
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                  to="/change-password">
                  Change Password
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="header-right d-flex">
            <button
              onClick={notificationHandler}
              className="bg-dark text-light notif-btn"
              style={{ marginTop: "5px" }}>
              <FontAwesomeIcon icon={faBell} />
              {status == 1 && <div className="notif-status"></div>}
            </button>
          </div>
        </div>
      </div>
      <div className="btm-header-rit-top"></div>
    </div>
  );
};

export default TopHeader;
