import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const blogList = () => {
  return httpClient.get(URL.BLOG_LIST);
};

const addBlog = (data) => {
  return httpClient.post(URL.BLOG_LIST, data);
};

const viewBlog = (id) => {
  return httpClient.get(URL.BLOG_VIEW + id);
};

const editBlog = (id, data) => {
  return httpClient.post(URL.BLOG_VIEW + id, data);
};

const statusBlog = (id) => {
  return httpClient.get(URL.BLOG_VIEW + "status" + "/" + id);
};

export const blogServices = {
  blogList,
  statusBlog,
  editBlog,
  viewBlog,
  addBlog,
};
