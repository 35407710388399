import React, { useEffect, useState } from "react";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import TopHeader1 from "../component/TopHeader1";
import ReactPaginate from "react-paginate";
import patientService from "../Services/Service/patient";
let active = 3;
let items = [];
for (let number = 1; number <= 4; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}
const data = [
  {
    id: 1,
    patId: "IOP006",
    name: "Ram",
    mobile: "8987898789",
    gender: "Male",
    address: "#3, Jiuo Street, Hiomai, Chennai - 609 0026",
    status: "Active",
    age: "50",
    visit: 8,
  },
  {
    id: 2,
    patId: "IOP007",
    name: "Akiul",
    mobile: "9098767809",
    gender: "Female",
    status: "Deactive",
    age: "66",
    visit: 3,
    address: "#3, Jiuo Street, Hiomai, Chennai - 609 0026",
  },
  {
    id: 3,
    patId: "IOP008",
    name: "Aloi Ramach",
    mobile: "8987898789",
    gender: "Male",
    status: "Active",
    age: "45",
    visit: 2,
    address: "#3, Jiuo Street, Hiomai, Chennai - 609 0026",
  },
  {
    id: 4,
    patId: "IOP009",
    name: "kaiuo Loip",
    mobile: "9098767809",
    gender: "Male",
    status: "Active",
    age: "62",
    visit: 6,
    address: "#3, Jiuo Street, Hiomai, Chennai - 609 0026",
  },
];

const HPatientPage = () => {
  const [adminData, setadminData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(data.status);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [patient, setPatient] = useState([]);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [searcharr, setSearchArr] = useState("");
  console.log(adminData);

  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter(
        (val) =>
          val?.name.toLowerCase().includes(searcharr.toLowerCase()) ||
          val?.mobile.toLowerCase().includes(searcharr.toLowerCase())
      );
      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    // console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const patientList = () => {
    patientService
      .Hpatient_List()
      .then((res) => {
        let temp = res.data?.patients.reverse();
        setadminData(temp);
      })
      .catch((e) => e);
  };

  console.log(adminData);
  useEffect(() => {
    patientList();
  }, []);
  return (
    <aside>
      <TopHeader1 type="patient" />
      <article className="p-3">
        <div className="ad-btn w-100 flex-ac-jb p-2">
          <div />
          <div className="flex-ac-jc">
            {/* <div className="px-1 col-sm-4 modal-label-input">
              <label className="text-primary-dark" for="">
                From Date
              </label>
              <input
                type="date"
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
                value={fromDate}
                className="bg-transparent"
              />
            </div>
            <div className="px-1 col-sm-4 modal-label-input">
              <label className="text-primary-dark" for="">
                To Date
              </label>
              <input
                type="date"
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                value={toDate}
                className="bg-transparent"
              />
            </div> */}
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
            {/* <button
              onClick={() => setAddModal(true)}
              className="comn-btn bg-primary1 px-3 py-2"
            >
              Add patient
            </button> */}
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table">
            <thead>
              <tr className="text-center">
                <th>S.No</th>
                {/* <th>Patient Name</th> */}
                <th>Name</th>
                <th>Mobile</th>
                <th>Gender</th>
                <th className="text-center">Age</th>
                <th className="text-center">No. of visit</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((list, index) => (
                <tr key={index} className="text-center">
                  <td>
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>
                  {/* <td>{list.patId}</td> */}
                  <td>{list.name}</td>
                  <td>{list.mobile}</td>
                  <td>{list.gender}</td>
                  <td className="text-center">{list.age}</td>
                  <td className="text-center">{list.appointments?.length}</td>

                  {/* <td className="text-center">
                    <button
                      className={
                        list.status == "Active"
                          ? "comn-btn active-deact-btn bg-greens"
                          : "comn-btn active-deact-btn bg-primary1"
                      }
                    >
                      {list.status}
                    </button>
                  </td>
                  <td className="flex-ac text-center">
                    <Link
                      to="/patient/patient-details"
                      className="comn-btn bg-yellows edit-btn-svg"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Link>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
          {items}
        </Pagination> */}
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </article>
    </aside>
  );
};

export default HPatientPage;
