import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { maleAvatar } from "../assets/img";
import HospitalCategories from "../component/HospitalCategories";
import PatientAppoinment from "../component/PatientAppoinment";
import Specialities from "../component/Specialities";
import TopHeader from "../component/TopHeader";
import TopHeader1 from "../component/TopHeader1";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { hospital } from "../Services/Service/hospital";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useHospitalAddDocterMutation } from "../redux/api/api";

const HAddDoctor = () => {
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();

  const [profileImage, setProfileImage] = useState("");
  const [specialities, setSpecialities] = useState([]);
  const [selected, setSelected] = useState([]);
  const [profilePreview, setProfilePreview] = useState();
  const [licence, setLicence] = useState();
  const [licencePreview, setLicencePreview] = useState();
  const [mui, setMui] = useState();
  const [muiPreview, setMuiPreview] = useState();

  const [inputs, setInputs] = useState();

  // RTK QUERY

  const [add_Docter] = useHospitalAddDocterMutation();

  useEffect(() => {
    hospital.hospitalCreate().then((res) => {
      let temp = [];
      res?.data?.specializations?.map((list) => {
        temp.push({ value: list.id, label: list.name });
      });

      setSpecialities(temp);
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };
  const notify = (error_msg) => {
    toast.error(error_msg);
  };

  //  ADD DOCTOR

  const [name, setName] = useState("");
  const [uname, setUname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [fee, setFee] = useState("");
  const [duration, setDuration] = useState("");
  const [age, setAge] = useState("");
  const [gen, setGen] = useState("");
  const [pass, setPass] = useState("");
  const [des, setDes] = useState("");
  const [rat, setRat] = useState("");
  const [spec, setSpec] = useState("");
  const [img, setImg] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [unameErr, setUnameErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [qualificationErr, setQualificationErr] = useState(false);
  const [experienceErr, setExperienceErr] = useState(false);
  const [feeErr, setFeeErr] = useState(false);
  const [durationErr, setDurationErr] = useState(false);
  const [ageErr, setAgeErr] = useState(false);
  const [genErr, setGenErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [desErr, setDesErr] = useState(false);
  const [ratErr, setRatErr] = useState(false);
  const [specErr, setSpecErr] = useState(false);
  const [imgErr, setImgErr] = useState(false);

  const addDoctor = () => {
    console.log("function called");
    if (
      name?.length == 0 ||
      uname?.length == 0 ||
      mobile?.length < 10 ||
      email?.length == 0 ||
      qualification?.length == 0 ||
      experience?.length == 0 ||
      fee?.length == 0 ||
      duration?.length == 0 ||
      age?.length == 0 ||
      gen?.length == 0 ||
      pass?.length < 8 ||
      des?.length == 0 ||
      rat?.length == 0 ||
      selected.length > 1 ||
      profileImage?.length == 0
    ) {
      setNameErr(true);
      setUnameErr(true);
      setMobileErr(true);
      setEmailErr(true);
      setQualificationErr(true);
      setExperienceErr(true);
      setFeeErr(true);
      setDurationErr(true);
      setAgeErr(true);
      setGenErr(true);
      setPassErr(true);
      setDesErr(true);
      setRatErr(true);
      setSpecErr(true);
      setImgErr(true);
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("qualification", qualification);
      formData.append("experience", experience);
      formData.append("username", uname);
      formData.append("password", pass);
      formData.append("image", profileImage);
      formData.append("interval_min", parseInt(duration));
      formData.append("total_fees", fee);
      formData.append("description", des);
      formData.append("mobile", parseInt(mobile));
      formData.append("email", email);
      formData.append("age", age);
      formData.append("gender", gen);
      formData.append("rating", rat);
      formData.append("license", profileImage);
      selected?.map((list, index) => {
        setSpec(list.value);
        formData.append(`speciality_ids[${index}]`, list.value);
      });
      // hospital
      add_Docter(formData)
        .then((res) => {
          console.log("res", res);
          swal(res?.data?.message, "", "success");
          navigate("/doctors");
        })
        .catch((e) => {
          ErrorHandler(e);
        });
    }
  };

  // console.log(inputs);

  useEffect(() => {
    if (!mui) {
      setMuiPreview(undefined);
      return;
    }
    const profileUrlll = URL.createObjectURL(mui);
    setMuiPreview(profileUrlll);
  }, [mui]);

  useEffect(() => {
    if (!licence) {
      setLicencePreview(undefined);
      return;
    }
    const profileUrll = URL.createObjectURL(licence);
    setLicencePreview(profileUrll);
  }, [licence]);

  useEffect(() => {
    if (!profileImage) {
      setProfilePreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(profileImage);
    setProfilePreview(profileUrl);
  }, [profileImage]);

  const onClickMui = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setMui(undefined);
      return;
    }
    setMui(e.target.files[0]);
  };
  const onClickLicence = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setLicence(undefined);
      return;
    }
    setLicence(e.target.files[0]);
  };
  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setProfileImage(undefined);
      return;
    }
    setProfileImage(e.target.files[0]);
    // setImg(e.target.files[0])
  };
  // console.log(selected);
  return (
    <aside className="">
      <TopHeader1 type="add-doctor" />
      <article>
        <div className="p-3">
          <Toaster position="top-right" reverseOrder={false} />

          <div className="p-3 bg-white rounded-2">
            {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
              <div className="form-check ps-0">
                <div className="px-1 col-sm-12 modal-label-input">
                  <label className="text-primary-dark" for="">
                    Autority Name
                  </label>
                  <select name="" id="" className="bg-background">
                    <option value="Ramesh">Ramesh</option>
                    <option value="Rahesh">Rahesh</option>
                  </select>
                </div>
              </div>
            </div> */}
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Name
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      id="example-if-email"
                      name="name"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                    {name?.length == 0 && nameErr && (
                      <span className="err">enter the name</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      UserName
                    </label>
                    <input
                      onChange={(e) => setUname(e.target.value)}
                      type="text"
                      id="example-if-email"
                      name="username"
                      placeholder="userName"
                      className="bg-transparent"
                      value={uname}
                    />
                    {uname?.length == 0 && unameErr && (
                      <span className="err">enter the username</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Mobile
                    </label>
                    <input
                      onChange={(e) => setMobile(e.target.value)}
                      type="Name"
                      id="example-if-email"
                      name="mobile"
                      placeholder="mobile"
                      className="bg-transparent"
                    />
                    {mobile?.length == 0 && mobileErr && (
                      <span className="err">enter the mobile number</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email
                    </label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      id="example-if-email"
                      name="email"
                      placeholder="Email"
                      className="bg-transparent"
                    />
                    {email?.length == 0 && emailErr && (
                      <span className="err">enter the email</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Qualification
                    </label>
                    <input
                      onChange={(e) => setQualification(e.target.value)}
                      type="text"
                      id="example-if-email"
                      name="qualification"
                      placeholder="Qualification"
                      className="bg-transparent"
                    />
                    {qualification?.length == 0 && qualificationErr && (
                      <span className="err">enter the qualification</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Experience ( Year )
                    </label>
                    <input
                      onChange={(e) => setExperience(e.target.value)}
                      type="text"
                      id="example-if-email"
                      name="experience"
                      placeholder="Experience"
                      className="bg-transparent"
                    />
                    {experience?.length == 0 && experienceErr && (
                      <span className="err">enter the experience</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Appointment Fee ( ₹ )
                    </label>
                    <input
                      onChange={(e) => setFee(e.target.value)}
                      type="text"
                      id="example-if-email"
                      name="total_fees"
                      placeholder="Appointment Fee"
                      className="bg-transparent"
                    />
                    {fee?.length == 0 && feeErr && (
                      <span className="err">enter the appoinment fee</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Duration ( min )
                    </label>
                    <input
                      onChange={(e) => setDuration(e.target.value)}
                      type="number"
                      id="example-if-email"
                      name="interval_min"
                      placeholder="Duration"
                      className="bg-transparent"
                    />
                    {duration?.length == 0 && durationErr && (
                      <span className="err">enter the duration</span>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Ratings
                    </label>
                    <input
                      onChange={handleChange}
                      type="number"
                      id="example-if-email"
                      name="rating"
                      placeholder="Ratings"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Age
                    </label>
                    <input
                      onChange={(e) => setAge(e.target.value)}
                      type="number"
                      id="example-if-email"
                      name="age"
                      placeholder="Age"
                      className="bg-transparent"
                    />
                    {age?.length == 0 && ageErr && (
                      <span className="err">enter the age</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Gender
                    </label>
                    <input
                      onChange={(e) => setGen(e.target.value)}
                      type="text"
                      id="example-if-email"
                      name="gender"
                      placeholder="Gender"
                      className="bg-transparent"
                    />
                    {gen?.length == 0 && genErr && (
                      <span className="err">enter the gender</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Password
                    </label>
                    <input
                      onChange={(e) => setPass(e.target.value)}
                      type="password"
                      id="example-if-email"
                      name="password"
                      placeholder="Password"
                      className="bg-transparent"
                    />
                    {pass?.length < 8 && passErr && (
                      <span className="err">enter the password</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group col-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Description
                    </label>
                    <textarea
                      placeholder="About"
                      onChange={(e) => setDes(e.target.value)}
                      className="bg-transparent"
                      name="description"
                      id=""
                      cols="30"
                      rows="10"></textarea>
                    {des?.length == 0 && desErr && (
                      <span className="err">enter the description</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Rating
                    </label>
                    <input
                      placeholder="rating"
                      onChange={(e) => setRat(e.target.value)}
                      className="bg-transparent"
                      name="rating"
                      id=""></input>
                    {rat?.length == 0 && ratErr && (
                      <span className="err">enter the rating</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Specialities
                    </label>
                    <Select
                      onChange={(e) => setSelected(e)}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      // defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={specialities}
                    />
                    <br></br>
                    {selected.length == 0 && specErr && (
                      <span className="err">select the option</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-sm-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Profile Image
                    </label>
                    <input type="file" onChange={onClickImage} />
                    {profileImage?.length == 0 && imgErr && (
                      <span className="err">enter the image</span>
                    )}
                    {profileImage && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={profilePreview} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Licence Image
                    </label>
                    <input type="file" onChange={onClickLicence} />
                    {licence && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={licencePreview} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      MUI
                    </label>
                    <input type="file" onChange={onClickMui} />
                    {mui && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={muiPreview} />
                      </div>
                    )}
                  </div>
              </div>
                </div> */}
            </Form>
            <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
              {/* <button className="comn-btn bg-hash2 px-3 py-2 me-2">
                Cancel
              </button> */}
              <button
                onClick={addDoctor}
                type="button"
                className="comn-btn bg-blues px-3 py-2 me-2"
                data-dismiss="modal">
                Submit
              </button>
            </div>
          </div>
        </div>
      </article>
      {/* <HospitalCategories /> */}
    </aside>
  );
};

export default HAddDoctor;
