import React from "react";
import {
  faBell,
  faBellSlash,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { notification } from "../Services/Service/notification";
import { useState } from "react";
import { useEffect } from "react";
import { useLazyLogoutQuery } from "../redux/api/api";

const TopHeader1 = (props) => {
  const [status, setstatus] = useState(false);
  const navigate = useNavigate();

  // RTK Query

  const [logout] = useLazyLogoutQuery();

  const logoutAccount = () => {
    console.log("Logout");
    logout()
      .unwrap()
      .then((res) => {
        console.log("res", res);
        if (res?.status == "success") {
          navigate("/signin");
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 400);
        }
      });
  };

  const logoutAccount1 = () => {
    navigate("/signin");
    localStorage.clear();
    window.location.href = "/";
  };
  const notificationHandler = () => {
    navigate("/notifications");
    // notification
    //   .adminReadClick()
    //   .then((resp) => {
    //     console.log(resp);
    //   })
    //   .catch((err) => err);
  };
  const statusAPi = () => {
    notification
      .readStatus()
      .then((resp) => {
        console.log(resp);
        setstatus(resp?.data?.read_status);
      })
      .catch((err) => err);
  };
  useEffect(() => {
    statusAPi();
  }, []);

  return (
    <div className="">
      <div className="flex-ac-jb header-rit-top px-4 py-2">
        <div className="">
          {props.type == "crm-dashboard" && <h4>Dashboard</h4>}
          {props.type == "role" && <h4>Roles</h4>}
          {props.type == "add-role" && (
            <div className="">
              <h4>Add Roles</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <a href="#">
                    <FontAwesomeIcon icon={faHome} /> Roles
                  </a>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Roles</li>
              </ul>
            </div>
          )}
          {props.type == "patient" && <h4>Patients</h4>}
          {props.type == "patient-detail" && (
            <div className="">
              <h4>Patient Details</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/patient">
                    <FontAwesomeIcon icon={faHome} /> Patients
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Patients Details</li>
              </ul>
            </div>
          )}
          {props.type == "doctors" && <h4>Doctors</h4>}
          {props.type == "add-doctor" && (
            <div className="">
              <h4>Add Doctor</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/doctors">
                    <FontAwesomeIcon icon={faHome} /> Doctor
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Doctor</li>
              </ul>
            </div>
          )}
          {props.type == "view-doctor" && (
            <div className="">
              <h4>View Doctor</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/doctors">
                    <FontAwesomeIcon icon={faHome} /> Doctor
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>View Doctor</li>
              </ul>
            </div>
          )}
          {props.type == "edit-doctor" && (
            <div className="">
              <h4>Edit Doctor</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/doctors">
                    <FontAwesomeIcon icon={faHome} /> Doctor
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit Doctor</li>
              </ul>
            </div>
          )}
          {props.type == "hospital-detail" && (
            <div className="">
              <h4>Hospital Details</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/hospital">
                    <FontAwesomeIcon icon={faHome} /> Hospitals
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Hospital Details</li>
              </ul>
            </div>
          )}
          {props.type == "hospital-adit" && (
            <div className="">
              <h4>Hospital Edit</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/hospital">
                    <FontAwesomeIcon icon={faHome} /> Hospitals
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Hospital Edit</li>
              </ul>
            </div>
          )}
          {props.type == "appointment" && <h4>Appoinments</h4>}
          {props.type == "help-support" && <h4>Help & Support</h4>}
          {props.type == "report-list" && <h4>Report</h4>}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Dropdown>
            <div className="header-right d-flex">
              <Dropdown.Toggle
                className="border-0"
                style={{ background: "transparent" }}
                id="dropdown-basic"
              >
                <a className="bg-dark text-light">
                  <FontAwesomeIcon icon={faUser} />
                </a>
              </Dropdown.Toggle>
            </div>
            <Dropdown.Menu>
              <button onClick={logoutAccount} className="header-logout">
                Logout
              </button>
              <Dropdown.Item href="#/change-password?type=hospital">
                Change Password
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="header-right d-flex">
            <button
              onClick={notificationHandler}
              className="bg-dark text-light notif-btn"
              style={{ marginTop: "5px" }}
            >
              <FontAwesomeIcon icon={faBell} />
              {status == 1 && <div className="notif-status"></div>}
            </button>
          </div>
        </div>
      </div>
      <div className="btm-header-rit-top"></div>
    </div>
  );
};

export default TopHeader1;
