// export const BASE_URL = "https://pariseva.net/crmapi/"; //Live
export const BASE_URL = "https://backend.pariseva.net/crmapi/";
// export const BASE_URL = "http://192.168.1.61/pariseva_backend/public/crmapi/"; //Local ❌

// export const BASE_URL = "http://192.168.1.61/pariseva_laravel_script/public/"; //Local

export const URL = {
  //LOGIN
  LOGIN: "admin/login",
  CHANGE_PASSWORD: "admin/change-password",
  LOGOUT: "admin-logout",

  //HOSPITAL
  HOSPITAL_LIST: "hospital",
  HOSPITAL_ADD: "hospital",
  HOSPITAL_VIEW: "hospital/",
  HOSPITAL_UPDATE: "hospital/",
  HOSPITAL_STATUS: "hospital/status/",
  HOSPITAL_DOCTOR: "hospital-doctor",

  //ROLE
  ROLE_LIST: "role",
  ROLE_ADD: "role",
  ROLE_EDIT: "role/",
  ROLE_UPDATE: "role/",
  ROLE_CREATE: "role/create",
  // STATUS_ROLE: "role/status/",
};

export const TOKEN = "TOKEN";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
