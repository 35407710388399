import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import Loader from "react-loader";
import AdminServices from "../Services/Service/adminService";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../Services/Storage/user";

const AdminPage = () => {
  const { user } = usePermissions();
  // console.log(user);
  const [adminData, setadminData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState();
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [role, setRole] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");
  const [editid, seteditId] = useState();

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name?.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  // useEffect(() => {
  //   let temp = [...adminData];

  //   temp.slice(start, end);
  // }, []);

  // const setPaginate = (number) => {
  //   let temp = number;

  //   console.log(temp);
  // };

  // let active = 3;
  // let items = [];
  // for (let number = 1; number <= Math.ceil(adminData.length / 10); number++) {
  //   items.push(
  //     <Pagination.Item
  //       onClick={() => setPaginate(number)}
  //       key={number}
  //       active={number === active}
  //     >
  //       {number}
  //     </Pagination.Item>
  //   );
  // }

  const adminList = () => {
    AdminServices.adminList()
      .then((res) => {
        // console.log(res.data);
        let temp = res.data?.admins.reverse();
        setadminData(temp);
        setLoading(false);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };
  useEffect(() => {
    adminList();
    AdminServices.adminCreate().then((res) => {
      setRole(res.data.roles);
    });
  }, []);
  const viewAdmin = (id) => {
    seteditId(id);
    let admin = {};
    AdminServices.adminEdit(id)

      .then((res) => {
        admin.name = res.data.admin?.name;
        admin.username = res.data.admin?.username;
        admin.email = res.data.admin?.email;
        admin.mobile = res.data.admin?.mobile;
        admin.type = res.data.admin?.role?.id;

        setInputs(admin);

        setEditModal(true);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;

    setInputs((Values) => ({ ...Values, [name]: value }));
  };

  const statusChange = (id) => {
    AdminServices.adminStatus(id).then((res) => {
      swal(res.data.message, "", "success");
      adminList();
    });
  };
  const notify = (error_msg) => {
    toast.error(error_msg);
  };

  //  ADD ADMIN

  const [nameErr, setNameErr] = useState(false);
  const [unameErr, usetNameErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [cPassErr, setCpassErr] = useState(false);
  const [aRole, setARole] = useState(false);
  const [passC, setPassC] = useState(false);
  const [CpassCh, setCpassChe] = useState(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [cPass, setCpass] = useState("");
  const [roles, setRoles] = useState("");

  const addAdmin = () => {
    if (
      name?.length == 0 ||
      username?.length == 0 ||
      number?.length == 0 ||
      email?.length == 0 ||
      pass?.length < 8 ||
      cPass?.length == 0 ||
      roles?.length == 0 ||
      pass !== cPass
    ) {
      setNameErr(true);
      usetNameErr(true);
      setMobileErr(true);
      setEmailErr(true);
      setPassErr(true);
      setCpassErr(true);
      setARole(true);
      setPassC(true);
      setPassC(true);
    } else {
      let formdata = new FormData();

      formdata.append("name", name);
      formdata.append("username", username);
      formdata.append("email", email);
      formdata.append("mobile", number);

      formdata.append("password", pass);
      formdata.append("password_confirmation", cPass);
      formdata.append("role_id", roles);
      AdminServices.adminAdd(formdata)
        .then((res) => {
          adminList();
          // setAddModal(false);
          setAddModal(false);
          swal(res.data.message, "", "success");
        })
        .catch((e) => {
          ErrorHandler(e);
        });
    }
  };

  const addAdmins = () => {
    let formdata = new FormData();
    let passwordCheck = true;
    let lengthCheck = true;

    if (inputs?.password !== inputs?.confirm_password) {
      passwordCheck = false;
      // console.log("enter ");
    }

    if (inputs?.password?.length < 8 || inputs?.confirm_password?.length < 8) {
      // console.log("enterd length");
      lengthCheck = false;
    }

    formdata.append("name", inputs?.name);
    formdata.append("username", inputs?.username);
    formdata.append("email", inputs?.email);
    formdata.append("mobile", inputs?.mobile);

    formdata.append("password", inputs?.password);
    formdata.append("password_confirmation", inputs?.confirm_password);
    formdata.append("role_id", inputs?.type);

    if (
      inputs?.name &&
      inputs?.username &&
      inputs?.email &&
      inputs?.mobile &&
      inputs?.password &&
      inputs?.confirm_password &&
      passwordCheck &&
      inputs?.type &&
      lengthCheck
    ) {
      setAddModal(false);
      AdminServices.adminAdd(formdata)
        .then((res) => {
          adminList();
          // setAddModal(false);
          setInputs("");
          swal(res.data.message, "", "success");
        })
        .catch((e) => {
          ErrorHandler(e);
        });
    } else if (!inputs?.name) {
      setNameErr(true);
      // notify("please Enter Name");
    } else if (!inputs?.username) {
      usetNameErr(true);
      // notify("please Enter userName");
    } else if (!inputs?.mobile) {
      setMobileErr(true);
      // notify("please Enter Mobile Number");
    } else if (!inputs?.email) {
      setEmailErr(true);
      // notify("please Enter Email Address");
    } else if (!inputs?.password) {
      setPassErr(true);
      // notify("please Enter Password");
    } else if (!inputs?.confirm_password) {
      setCpassErr(true);
      // notify("please Enter Confirm Password");
    } else if (!inputs?.type) {
      setARole(true);
      // notify("please Select Role");
    } else if (!passwordCheck) {
      setPassC(true);
      // notify("Password & confirm Password Should be Same");
    } else if (!lengthCheck) {
      setCpassChe(true);
      // notify("Password Length Should be Greater or Equal To 8 Characters");
    }
  };

  const editAdmin = () => {
    let formdata = new FormData();

    formdata.append("name", inputs?.name);
    formdata.append("_method", "PUT");

    formdata.append("username", inputs?.username);
    formdata.append("email", inputs?.email);
    formdata.append("mobile", inputs?.mobile);

    if (inputs?.password && inputs?.confirm_password) {
      formdata.append("password", inputs?.password);
      formdata.append("password_confirmation", inputs?.confirm_password);
    }

    formdata.append("role_id", inputs?.type);

    if (
      inputs?.name &&
      inputs?.username &&
      inputs?.email &&
      inputs?.mobile &&
      inputs?.type
    ) {
      setEditModal(false);
      AdminServices.adminUpdate(formdata, editid)
        .then((res) => {
          adminList();
          // setEditModal(false);
          setInputs("");
          swal(res.data.message, "", "success");
        })
        .catch((e) => {
          ErrorHandler(e);
        });
    } else if (!inputs?.name) {
      notify("please Enter Name");
    } else if (!inputs?.username) {
      notify("please Enter userName");
    } else if (!inputs?.mobile) {
      notify("please Enter Mobile Number");
    } else if (!inputs?.email) {
      notify("please Enter Email Address");
    } else if (!inputs?.type) {
      notify("please Select Role");
    }
  };

  return (
    <aside>
      <TopHeader type="admin" />
      <article className="p-3">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="ad-btn w-100 flex-ac-jb p-2">
          <div></div>
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
            {user?.includes("CAN-ADD-ADMINS") ? (
              <button
                onClick={() => {
                  setInputs("");
                  setAddModal(true);
                }}
                className="comn-btn bg-primary1 px-3 py-2"
              >
                Add Admin
              </button>
            ) : null}
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table">
            <thead>
              <tr className="text-center">
                <th>S.No</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Role</th>
                {user?.includes("CAN-STATUS-ADMINS") ? (
                  <th className="text-center">Status</th>
                ) : null}

                {user?.includes("CAN-EDIT-ADMINS") ? (
                  <th className="text-center">Action</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((list, index) => (
                <tr key={index} className="text-center">
                  <td>
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>
                  <td>{list.name}</td>
                  <td>{list.mobile}</td>
                  <td>{list.role.role}</td>
                  {user?.includes("CAN-STATUS-ADMINS") ? (
                    <td className="text-center">
                      <button
                        onClick={() => statusChange(list.id)}
                        className={
                          list.status == 1
                            ? "comn-btn active-deact-btn bg-greens"
                            : "comn-btn active-deact-btn bg-primary1"
                        }
                      >
                        {list.status == 1 ? "Active" : "InActive"}
                      </button>
                    </td>
                  ) : null}
                  {user?.includes("CAN-EDIT-ADMINS") ? (
                    <td className="flex-ac text-center">
                      <button
                        onClick={() => viewAdmin(list.id)}
                        className="comn-btn bg-blues edit-btn-svg"
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
          {items}
        </Pagination> */}
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        <Modal
          className="table-modal"
          show={addModal}
          onHide={() => setAddModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>Add Admin</h4>
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Name *
              </label>
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                id="example-if-email"
                name="name"
                placeholder="Enter The Name"
              />
              {name?.length == 0 && nameErr && (
                <span className="err">Enter the name</span>
              )}
              {/* {hName?.length == 0 && hNameErr && (
                      <p className="err">Enter Hospital Name</p>
                    )} */}
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Username *
              </label>
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                id="example-if-email"
                name="username"
                placeholder="Enter The Username"
              />
              {username?.length == 0 && unameErr && (
                <span className="err">Enter the username</span>
              )}
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Mobile No *
              </label>
              <input
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                type="number"
                id="example-if-email"
                name="mobile"
                placeholder="Enter The Mobile No"
              />
              {number?.length == 0 && mobileErr && (
                <span className="err">Enter Mobile Number</span>
              )}
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Email *
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                id="example-if-email"
                name="email"
                placeholder="Enter The Email"
              />
              {email?.length == 0 && emailErr && (
                <span className="err">Enter Valid Email</span>
              )}
            </div>

            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Password *
              </label>
              <input
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                type="password"
                id="example-if-email"
                name="password"
                placeholder="Enter The Password"
              />
              {pass?.length < 8 && passErr && (
                <span className="err">Enter 8 digit password</span>
              )}
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Confirm Password *
              </label>
              <input
                value={cPass}
                onChange={(e) => setCpass(e.target.value)}
                type="password"
                id="example-if-email"
                name="confirm_password"
                placeholder="Enter The Confirm Password"
              />
              {cPass?.length < 8 && cPassErr && (
                <span className="err">Enter 8 digit password</span>
              )}
              {/* {cPass !== pass ? (
                <span>
                  Password Length Should be Greater or Equal To 8 Characters
                </span>
              ) : (
                ""
              )} */}
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Admin Type *
              </label>
              <select
                value={roles}
                onChange={(e) => setRoles(e.target.value)}
                name="type"
                id=""
              >
                <option value="">Select Role</option>
                {role?.map((list) => {
                  return <option value={list?.id}>{list?.code}</option>;
                })}

                {/* <option value="Admin">Admin</option> */}
              </select>
              {roles?.length == 0 && aRole && (
                <span className="err">Choose the field</span>
              )}
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={() => setAddModal(false)}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Cancel
            </button>
            <button
              onClick={addAdmin}
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </Modal>
        <Modal
          className="table-modal"
          show={editModal}
          onHide={() => setEditModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>Edit Admin</h4>
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Name *
              </label>
              <input
                type="text"
                name="name"
                id="example-if-email"
                value={inputs?.name}
                onChange={handleChange}
                placeholder="Enter The Name"
              />
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Username *
              </label>
              <input
                value={inputs?.username}
                onChange={handleChange}
                type="text"
                id="example-if-email"
                name="username"
                placeholder="Enter The Username"
              />
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Mobile No *
              </label>
              <input
                onChange={handleChange}
                type="number"
                value={inputs?.mobile}
                name="mobile"
                id="example-if-email"
                placeholder="Enter The Mobile No"
              />
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Email *
              </label>
              <input
                onChange={handleChange}
                type="email"
                value={inputs?.email}
                name="email"
                id="example-if-email"
                placeholder="Enter The Email"
              />
            </div>

            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Password *
              </label>
              <input
                value={inputs?.password}
                onChange={handleChange}
                type="password"
                id="example-if-email"
                name="password"
                placeholder="Enter The Password"
              />
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Confirm Password *
              </label>
              <input
                value={inputs?.confirm_password}
                onChange={handleChange}
                type="password"
                id="example-if-email"
                name="confirm_password"
                placeholder="Enter The Confirm Password"
              />
            </div>
            <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Admin Type *
              </label>
              <select onChange={handleChange} value={inputs?.type} name="type">
                <option value="">Select Role</option>
                {role?.map((list) => {
                  return <option value={list?.id}>{list?.code}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={() => setEditModal(false)}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Cancel
            </button>
            <button
              onClick={editAdmin}
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </Modal>
      </article>
    </aside>
  );
};

export default AdminPage;
