import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const bannerList = () => {
  return httpClient.get(URL.BANNER_LIST);
};

const addBanner = (data) => {
  return httpClient.post(URL.BANNER_LIST, data);
};

const viewBanner = (id) => {
  return httpClient.get(URL.BANNER_VIEW + id);
};

const editBanner = (id, data) => {
  return httpClient.post(URL.BANNER_VIEW + id, data);
};

const statusBanner = (id) => {
  return httpClient.get(URL.BANNER_VIEW + "status" + "/" + id);
};

export const bannerServices = {
  bannerList,
  statusBanner,
  editBanner,
  viewBanner,
  addBanner,
};
