// @ts-nocheck
import React from "react";
import { Logo } from "../assets/img";
import Navbar from "../navbar/Navbar";
import Navbar1 from "../navbar/Navbar1";

const Header1 = () => {
  return (
    <header className="menu-bar1">
      <div className="top-head">
        <img src={Logo} className="logo" />
        <h5 className="app-name">Pariseva</h5>
      </div>
      <div className="btm-header-rit-top"></div>
      <Navbar1 />
    </header>
  );
};

export default Header1;
