// @ts-nocheck
import React, { useState } from "react";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Tab, Tabs } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import { doctor } from "../Services/Service/doctor";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import DataTable from "../component/Table";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import RejectedTable from "../component/RejectedTable";

const DoctorPage = () => {
  const [adminData, setadminData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  // console.log(adminData);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [doctorsList, setDoctorsList] = useState([]);

  const [searcharr, setSearchArr] = useState("");
  const [rejected, setRejected] = useState([]);

  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name.toLowerCase().includes(searcharr.toLowerCase())
      );
      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);
    let temp2 = event.selected;
    setCounter(temp2);
    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value));
  };

  useEffect(() => {
    doctor
      .requestedList()
      .then((res) => {
        let temp = res.data?.doctors.reverse();
        setadminData(temp);
        setLoading(false);
      })
      .catch((e) => {
        ErrorHandler(e);
      });

    doctor
      .rejectedList()
      .then((res) => {
        let temp = res.data?.doctors.reverse();
        setRejected(temp);
        // console.log(res);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  }, []);
  return (
    <aside>
      <TopHeader type="doctor" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="doctor-req"
          id="fill-tab-example"
          className="tab-appoinment"
          fill
        >
          <Tab eventKey="doctor-req" title="Request">
            <div className="ad-btn w-100 flex-ac-jb p-2">
              <div></div>
              <div className="flex-ac-jc">
                <Form className="search-input me-2">
                  <input
                    onChange={(e) => setSearchArr(e.target.value)}
                    placeholder="search..."
                    type="search"
                    className="bg-transparent rounded-1 px-2 py-1"
                  />
                </Form>
                {/* <button
              onClick={() => setAddModal(true)}
              className="comn-btn bg-primary1 px-3 py-2"
            >
              Add patient
            </button> */}
              </div>
            </div>
            <div className="p-2 bg-white rounded-2">
              <table className="custom-table">
                <thead>
                  <tr className="text-center">
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    {/* <th>Address</th> */}
                    {/* <th className="text-center">Status</th> */}
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((list, index) => (
                    <tr key={index} className="text-center">
                      <td>
                        {index == 9
                          ? last
                          : counter
                          ? `${counter}${index + 1}`
                          : index + 1}
                      </td>
                      <td>{list.name}</td>
                      <td>{list?.detail?.mobile}</td>
                      <td>{list?.detail?.email || "Not Mentioned"}</td>
                      {/* <td>{list.address}</td> */}
                      {/* <td className="text-center">
                        <button
                          className={
                            list.status == "Active"
                              ? "comn-btn active-deact-btn bg-greens"
                              : "comn-btn active-deact-btn bg-primary1"
                          }
                        >
                          {list.status}
                        </button>
                      </td> */}
                      <td className="flex-ac text-center">
                        <Link
                          to="/doctor/doctor-request"
                          state={{ id: list?.id }}
                          className="comn-btn bg-yellows edit-btn-svg"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
              <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </Tab>
          <Tab eventKey="doctor-list" title="Doctors">
            <DataTable />
          </Tab>

          <Tab eventKey="doctor-rej" title="Rejected">
            <RejectedTable
              adminData={rejected}
              // rejecteedList
            />
          </Tab>
        </Tabs>
      </article>
      <Loader
        loaded={!loading}
        lines={12}
        length={12}
        width={4}
        radius={25}
        corners={2}
        rotate={0}
        direction={-1}
        color="#FF5A6A"
        speed={1}
        trail={60}
        shadow={false}
        hwaccel={true}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="55%"
        scale={1.0}
        loadedClassName="loadedContent"
      />
    </aside>
  );
};

export default DoctorPage;
