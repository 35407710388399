// export const BASE_URL = "https://pariseva.tallytraininginchennai.in/public/";
// export const BASE_URL = "https://pariseva.net/";
export const BASE_URL = "https://backend.pariseva.net/";
// export const BASE_URL = "http://192.168.1.46/pariseva_backend/public"; ❌
// export const BASE_URL = "http://192.168.1.61/pariseva_laravel_script/public/";

export const URL = {
  //LOGIN
  LOGIN: "crmapi/admin/login",
  LOGOUT: "crmapi/admin/logout",
  CHANGE_PASSWORD: "crmapi/admin/change-password",

  //DASHBOARD
  DASHBOARD: "crmapi/dashboard",
  CHANGE_FEES: "crmapi/doctor/percentage",

  //ADMIN
  ADMIN_LIST: "crmapi/admin",
  ADMIN_ADD: "crmapi/admin",
  ADMIN_CREATE: "crmapi/active-roles",
  ADMIN_EDIT: "crmapi/admin/",
  ADMIN_UPDATE: "crmapi/admin/",
  ADMIN_DELETE: "crmapi/admin/",
  STATUS_ADMIN: "crmapi/admin/status/",

  //ROLE
  ROLE_LIST: "crmapi/role",
  ROLE_ADD: "crmapi/role",
  ROLE_EDIT: "crmapi/role/",
  ROLE_UPDATE: "crmapi/role/",

  ROLE_CREATE: "crmapi/role/create",

  STATUS_ROLE: "crmapi/role/status/",

  //PATIENT
  PATIENT: "crmapi/patient/",
  PATIENT_LIST: "crmapi/patient/list",

  // history/appointments

  //APPOINTMENTS
  RESCHEDULEHISTORY: "crmapi/reschedule/appointments",
  APPOITMNENTHISTORY: "crmapi/history/appointments",
  UPCOMINGHISTORY: "crmapi/upcomings/appointments",
  RESCHEDULE: "crmapi/reschedule",
  //DOCTOR

  REQUESTED_DOCTORS: "crmapi/doctors-requested",
  DOCTORS_LIST: "crmapi/doctors",
  VIEW_DOCTOR: "crmapi/doctor-view/",
  PROCESS_DOCTOR: "crmapi/doctor/accept-reject/",
  ALL_DOCTOR_STATUS: "crmapi/doctor-status/",
  DOCTOR_STATUS: "crmapi/doctor-status/",

  //HOSPITAL
  HOSPITAL_LIST: "crmapi/hospital",
  HOSPITAL_ADD: "crmapi/hospital",
  HOSPITAL_VIEW: "crmapi/hospital/",
  // HOSPITAL_VIEW:"crmapi/hospital/",
  HOSPITAL_UPDATE: "crmapi/hospital/",
  HOSPITAL_STATUS: "crmapi/hospital/status/",
  HOSPITAL_DOCTOR: "crmapi/hospital-doctor",
  HOSPITAL_CREATE: "crmapi/hospital-doctor/create",
  HOSPITAL_DOCTOR_LIST: "crmapi/hospital-doctor/",

  //categories

  CATEOGRIES_LIST: "crmapi/speciality",
  CATEOGRIES_VIEW: "crmapi/speciality/",

  //BANNER
  BANNER_LIST: "crmapi/banner",
  BANNER_VIEW: "crmapi/banner/",

  //Blog
  BLOG_LIST: "crmapi/blog",
  BLOG_VIEW: "crmapi/blog/",

  //APPOINTMENT,
  APPOINTMENT_LIST: "crmapi/appointment",

  //REPORT
  HOSPITAL_REPORT_LIST: "crmapi/hospital-reports",
  DOCTOR_REPORT_LIST: "crmapi/doctor-reports",
  HOSPITAL_DOCTOR_REPORT: "crmapi/hospital-doctor-reports",
  UPLOAD_REPORT: "crmapi/import-payment-file",
  DOWNLOAD_REPORT: "crmapi/payment-list",

  //FAQ
  FAQ_LIST: "crmapi/faq",
  FAQ_VIEW: "crmapi/faq/",

  //SUPPORT
  SUPPORT_LIST: "crmapi/support-raised",
  HOSPITAL_SUPPORT: "crmapi/hospital-support/request",
  HOSPITAL_SUPPORT_LIST: "crmapi/hospital-support/raised",
  HOSPITAL_SUPPORT_REPLYIED: "crmapi/hospital-support/replied",
  VIEW_SUPPORT: "crmapi/support/view/",
  REPLY_SUPPORT: "crmapi/reply-support",
  ANSWERED_SUPPORT: "crmapi/support-replied",

  //TIMEsLOTS
  TIMESLOTS: "/api/doctor/slots",

  //notification
  ADMIN_NOTIFICATION_LIST: "crmapi/all-notifications",
  HOSPITAL_NOTIFICATION_LIST: "crmapi/hospital-notifications",
  ADMIN_READ_CLICK: "crmapi/notification-read-entry",
  READ_STATUS: "crmapi/notification-read-status",
  // ADMIN_READ_STATUS:""
};
